import React, { Component } from 'react';
import { Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
//import { whiteLabel } from 'Whitelabel';
import { apiCall } from 'CommonFunctions';
import DatePicker from "react-datepicker";
import moment from 'moment';

//const restrictedList = whiteLabel('restrictedList');

export default class CouponCodes extends Component {

  state = {
    CouponList: [],
    origCouponList: [],
    couponName: '',
    searchCoupon: '',
    expiryDate: '',
    couponType: 'yearSub10',
    couponLimit: 1000
  }



  async loadCouponList() {
    const ret = await apiCall('readAllCoupons');
    this.setState({ origCouponList: ret.res, CouponList: ret.res });
  }

  async componentWillMount() {
    await this.loadCouponList();
  }


  filterBox = () => {
    return (
      <div style={{ width: 300, height: 70, marginTop: 10, marginBottom: 10 }}>
      <span style={{ fontSize: 13 }}>Search Coupon</span>
      <Input
          onChange={(e) => this.filterList(e.target.value,)}
          style={{ fontSize: 12 }}
          autoComplete='none'
          value={this.state.searchCoupon}
      />
      </div>
    );
  }

  filterList(text) {
    this.setState({ searchCoupon: text });
    const tmp = this.state.origCouponList.filter(function(item) {
     return item.coupon_code.toLowerCase().search(text.toLowerCase()) >= 0;
   });
   //If input is empty then set state back to full list
   this.setState({ CouponList: text === '' ? this.state.origCouponList : tmp });
  }

  removeCoupon = async (coupon_code) => {
    const r = window.confirm(`Do you really want to delete this Coupon? '${coupon_code}'`);
    if(r === true)
    {
      await apiCall('deleteCoupon', { coupon_code: coupon_code });
      await this.loadCouponList();
      this.clearAllInputs();
    }
  }

  clearAllInputs() {
    this.setState({ couponName: '', couponType: 'yearSub10', couponLimit: 1000});
  }

  addCoupon = async () => {
    if (this.state.couponLimit === 0) {
      window.confirm('Coupon limit must be greater than 0');
      return
    }

    if (this.state.couponName === '' || this.state.expiryDate === '') {
      window.confirm('Error with inputted fields');
      return
    }

    const datNow = new Date()
    const dateNowString = moment(datNow).format("YYYY-MM-DD")

    const dateExpiryString = moment(this.state.expiryDate).format("YYYY-MM-DD")

    await apiCall('addCoupon', {
      coupon_code: this.state.couponName.trim(),
      coupon_type: this.state.couponType,
      created_on: dateNowString,
      expiry_date: dateExpiryString,
      limit_use_to: this.state.couponLimit
    });
    await this.loadCouponList();
    this.clearAllInputs();
  }

  SetCouponLimitValue(num) {
    if (isNaN(num)) {
      //this.setState({couponLimit: 1000})
      return
    }
    //console.log(num)
    //console.log('isInteger', Number.isInteger(num))
    this.setState({couponLimit: num})
  }

  handleSubmit=(a)=> {
    
  }

  handleChange=(a)=> {
    this.setState({couponType: a.target.value});
  }

  dropDownCouponList =()=> {
    return (
      <form onSubmit={this.handleSubmit}>
          <select value={this.state.couponType} onChange={this.handleChange} style={{width: 140, height:30, borderRadius:4, borderColor: 'lightgray'}}>
             <option value={'yearSub10'}>{'10% Discount'}</option>
             <option value={'yearSub20'}>{'20% Discount'}</option>
             <option value={'yearSub50'}>{'50% Discount'}</option>
          </select>
      </form>
    )
  }

  render() {

      return (
        <div style={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 5, padding: 10, marginRight: 20, marginBottom: 100, width: 700 }}>
          <span style={{ marginBottom: 5 }}>Coupons</span>
          <div style={{ display: 'flex', borderStyle: 'solid', borderRadius: 5, padding: 5, borderWidth: 0.2, borderColor: 'rgba(0,0,0,0.3)' }}>
            <Button variant="contained" color="primary" style={{marginBottom: 5}} onClick={()=>this.addCoupon()}>Add Coupon</Button>
            <div style={{display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
              <span style={{marginTop: 5}}>Coupon Name</span>
              <Input
                  onChange={(e) => this.setState({ couponName: e.target.value })}
                  style={{ width: 300, fontSize: 12, marginBottom: 5 }}
                  value={this.state.couponName}
                  autoComplete='none'
              />
              <span style={{marginTop: 5}}>Coupon Expiry</span>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                autoComplete='none'
                selected={this.state.expiryDate}
                onChange={(e)=>{
                  this.setState({ expiryDate:e });
                }}
                />
                <span style={{marginTop: 10}}>Coupon Type</span>
                {/*
                <Input
                  style={{width: 100, marginTop: 5, fontSize: 12}}
                  onChange={(e) => this.setState({ couponType: e.target.value })}
                  value={this.state.couponType}
                  disabled={true}
                />
                */}
                {this.dropDownCouponList()}
                <span style={{marginTop: 10}}>Coupon Limit</span>
                <Input
                  style={{width: 100, marginTop: 5, fontSize: 12}}
                  onChange={(e) => this.SetCouponLimitValue(parseInt(e.target.value))}
                  value={this.state.couponLimit}
                />
              </div>
          </div>
          {this.filterBox()}
          <span style={{ fontSize: 14, fontWeight: 600 }}>Click on a row to delete (displays top 100 from search only)</span>

          <div style={{ display: 'flex', flexDirection: 'row', minWidth: 400, paddingTop: 10, paddingBottom: 0, fontWeight: 600 }}>
            <div style={{width: 150, fontSize: 12, textAlign: 'center'}}>Coupon Name</div>
            <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>Coupon Type</div>
            <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>Created On</div>
            <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>Expires On</div>
            <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>Limit Use To</div>
            <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>Times Used</div>
          </div>
          <div style={{ height: 500, overflow: 'scroll'}}>
          {this.state.CouponList.slice(0, 100).map((item, index) => {
            return (
              <div onClick={() => this.removeCoupon(item.coupon_code)} key={item.coupon_code} style={{ display: 'flex', flexDirection: 'row', minWidth: 400, paddingTop: 10, paddingBottom: 10, borderTopWidth: 0.5, borderTopColor: 'rgba(0,0,0,0.25)', borderTopStyle: 'solid'}}>
                <div style={{width: 150, fontSize: 12, textAlign: 'center'}}>{item.coupon_code}</div>
                <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>{item.coupon_type}</div>
                <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>{item.created_on}</div>
                <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>{item.expires_on}</div>
                <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>{item.limit_use_to}</div>
                <div style={{width: 100, fontSize: 12, textAlign: 'center'}}>{item.times_used}</div>
              </div>
            )
          })}
          </div>
        </div>
      )

  }
}
