import React from 'react';

import PlayLogo from 'Assets/images/play-button.svg';

import { whiteLabel } from 'Whitelabel';

const headerText = whiteLabel('headerText');
const headerBackground = whiteLabel('headerBackground');

export default function Header(props) {
  const backgroundStyle = {
    backgroundSize: "cover",
    backgroundImage: `url(${headerBackground})`,
    backgroundPosition: "center",
  }

  const styles = {
    slide: {opacity: 1, transition: 'opacity 1.5s', transitionDelay: '0.3s'},
    opacityFadeOut: {opacity: 0 },
  }

  return (
      <header style={backgroundStyle} className="header">
        <div className="header__container overlay_shadow_header">
          <h1 style={{padding: 0, margin: 0, marginTop: 50, fontSize: 80, fontWeight: 800}} className="header__container-heading customHeaderWelcome">Welcome</h1>
          <button style={{margin: 0, marginBottom: 20}} onClick={() => document.getElementById("movieShowcase").scrollIntoView()} className="header__container-btnPlay customHeaderButton">
            <PlayLogo className="header__container-btnMyList-play" style={{fontWeight: 200}}/>
            Start
          </button>
          <p style={{padding: 0, margin: 0, fontWeight: 200}} className="header__container-overview customHeaderText">{headerText}</p>
        </div>
        <div className="header--fadeBottom"></div>
      </header>
  )
}
