import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import { Link } from 'react-router-dom';


import Videos from './pages/videos'
import Streaming from './pages/streaming'
import PushBox from './pages/pushbox'
import AddPurchase from './pages/addPurchase'
import RestrictedList from './pages/restrictedlist'
import VideoUpload from './pages/video_upload'
import TagOrder from './pages/tagorder'
import Reports from './pages/reports'
import Coupons from './pages/coupons'

import { whiteLabel } from 'Whitelabel';
const adminPages = whiteLabel('adminPages');
const logo = whiteLabel('logo');


/*
To Do:
Make sure menu item only loads when whitelabel says so
*/

const menuItems = [
  {avail: adminPages.videos, title: 'Videos', component: <Videos/>, icon: require('./images/video.png'), iconBlue: require('./images/video-blue.png')},
  {avail: adminPages.streaming, title: 'Streaming', component: <Streaming/>, icon: require('./images/live-streaming.png'), iconBlue: require('./images/live-streaming-blue.png')},
  {avail: adminPages.push, title: 'Push Notifcation', component: <PushBox/>, icon: require('./images/notification.png'), iconBlue: require('./images/notification-blue.png')},
  {avail: adminPages.addPurchases, title: 'Add Purchases', component: <AddPurchase/>, icon: require('./images/purchase.png'), iconBlue: require('./images/purchase-blue.png')},
  {avail: adminPages.coupon, title: 'Coupons', component: <Coupons/>, icon: require('./images/tags.png'), iconBlue: require('./images/tags-blue.png')},
  {avail: adminPages.whitelist, title: 'White List', component: <RestrictedList/>, icon: require('./images/white-list.png'), iconBlue: require('./images/white-list-blue.png')},
  {avail: adminPages.uploadVideosVOD, title: 'Upload Videos', component: <VideoUpload/>, icon: require('./images/upload-video.png'), iconBlue: require('./images/upload-video-blue.png')},
  {avail: adminPages.tagOrdering, title: 'Tag Ordering', component: <TagOrder/>, icon: require('./images/tags.png'), iconBlue: require('./images/tags-blue.png')},
  {avail: true, title: 'Reports', component: <Reports/>, icon: require('./images/report.png'), iconBlue: require('./images/report-blue.png')},

].filter(item => item.avail === true)

class AdminPanel extends Component {

  constructor(props) {
     super(props);
      this.state = {
        drawerOpen: true,
        menuItem: 0,
        mouseOverMenuItem: -1,
      };
   }


   openMenu = () => {
     this.setState({ drawerOpen: !this.state.drawerOpen })
   }

   clickMenuItem = (menuNo) => {
     this.setState({ menuItem: menuNo});
   }

   mouseOverMenuItem = (menuNo) => {
     this.setState({ mouseOverMenuItem: menuNo});
   }


   render() {
     const styles = {
       menuStyleClosed: { backgroundColor: 'rgb(35,40,45)', width: 230, marginLeft: -230, height: '100%', position: 'absolute', transition: 'margin-left 0.5s'},
       menuStyleOpen:   { zIndex: 10, backgroundColor: 'rgb(35,40,45)', width: 230, marginLeft: 0, height: '100%', position: 'absolute', transition: 'margin-left 0.5s'},
       mainAreaMenuClosed: {marginLeft: 10, paddingRight: 10, paddingTop: 50,transition: 'margin-left 0.5s'},
       mainAreaMenuOpen: {marginLeft: 250, paddingRight: 10, paddingTop: 50, transition: 'margin-left 0.5s'},
       opacityFadeIn: {opacity: 1, transition: 'opacity 1.5s', transitionDelay: '0.3s'},
       opacityFadeOut: {opacity: 0 },
     }


     return (
       <div>
        <div id={'menu'} style={this.state.drawerOpen ? styles.menuStyleOpen : styles.menuStyleClosed}
        >

        {/*Back button and menu burger icon. Only menu burger when menu open*/}
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>


          <Link to='/'>
            <img
              alt={'back button'}
              src={require('./images/back-arrow-blue.png')}
              style={{cursor: 'pointer', width: 20, height: 20, marginTop: 14, marginLeft: 20}}
            />
          </Link>


          <img alt={'menu icon'} src={require('./images/menu-white.png')} onClick={() => this.openMenu()} style={{cursor: 'pointer', width: 20, height: 20, marginTop: 14, marginRight: 20}}/>

          </div>

            {/*Mapping through our menu items*/}
          <div id={'menu-items'} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10}}>

          <img
            alt={'logo'}
            src={logo}
             width="220" height="70"
            style={{ marginTop: 0, marginBottom: 15}}
          />

            {menuItems.map((item, index) =>
              <div
              onMouseOver={() => this.mouseOverMenuItem(index)}
              onMouseOut={()=>this.setState({ mouseOverMenuItem: -1})}
              onClick={() => this.clickMenuItem(index)}
              style={{ display: 'flex', cursor: 'pointer', height: 35, lineHeight: 35, backgroundColor: this.state.menuItem === index ? 'rgb(0,115,150)' : 'rgb(35,40,45)', width: '100%', }}>
                <p
                  key={index}
                  style={{fontSize: 14,
                    display: 'inline-block',
                    lineHeight: 'normal',
                    verticalAlign: 'middle',
                    fontWeight: 400,
                    //paddingLeft: 20,
                    margin: 'auto',
                    marginLeft: 0,
                    color: (this.state.mouseOverMenuItem === index && this.state.menuItem !== index)  ? 'rgb(0,185,235)' : 'white'}}
                >
                <img
                  alt={'menu list icon' + index}
                  src={(this.state.mouseOverMenuItem === index && this.state.menuItem !== index)  ? item.iconBlue : item.icon}
                  style={{width: 20, height: 20, margin: 'auto', marginLeft: 20, marginRight: 15, marginTop: -3}}
                />
                  {item.title}
                </p>
              </div>
              )}
          </div>


        </div>

        {/*Our main area*/}
        <div style={this.state.drawerOpen ? styles.mainAreaMenuOpen : styles.mainAreaMenuClosed}>


        <img
          alt={'menu icon'}
          src={require('./images/menu-black.png')}
          onClick={() => this.openMenu()}
          style={{cursor: 'pointer', position: 'absolute', left: 10, top: 15, width: 20, height: 20, ...this.state.drawerOpen ? styles.opacityFadeOut : styles.opacityFadeIn}}
        />

          {menuItems[this.state.menuItem].component}
        </div>

       </div>
     )
   }

}




// map state to props
const mapStateToProps = ({ reducerData, authUser }) => {
  return {
    reducerData: reducerData,
    authUser: authUser
  }
}

export default connect(mapStateToProps, actions)(AdminPanel);
