import React, {Component} from 'react';
import { apiCall } from 'CommonFunctions';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement((props) => <div style={{ borderWidth: 1, borderStyle: 'solid', display: 'flex', alignItems: 'center', borderColor: 'rgba(0,0,0,0.4)', width: 300, height: 40, padding: 5, color: 'rgba(0,0,0,0.6)'}}>{props.num} - {props.value}</div>);


const SortableList = SortableContainer(({items}) => {
  return (
    <div style={{ marginTop: 20 }}>
      {items.map((value, index) => (
          <SortableItem key={`${index}-${value}`} index={index} value={value} num={index + 1} />
      ))}
    </div>
  );
});


export default class tagorder extends Component {

  state = {
    items: [],
  }

  componentWillMount() {
    apiCall('getUserData', {admin: true})
    .then((retData) => {
      //Getting the row order from the server
        const unique = retData.resultsRowOrder.map(item => {
          return item.tag;
        });
        this.setState({ items: unique });
    });
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    console.log('in on sort end')
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }),()=>this.saveRowOrder());
  };

  async saveRowOrder() {
     await apiCall('saveRowOrder', {items: this.state.items});
   }

  render() {
    return (
      <div style={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 5, padding: 10, width: 350 }}>
        <span style={{ }}>Tag Ordering (Drag and drop)</span>
        <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
      </div>
    )
  }

}
