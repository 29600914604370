import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { apiCall } from 'CommonFunctions';
import { uploadVideoFile } from 'CommonFunctions';
import LoadingSpinner from 'Assets/images/loading.gif';
import { TableVideoList } from '../components/tableVideoList.js';
import { ClipLoader } from 'react-spinners';

/*
const MyTest = (props) => {
  return (
    <div>hello</div>
  )
}
*/

const bigData = {
  users: 'This report is the user data for all registred users',
  purchases: 'This report is for whenever a user has purchased something'
}

export default class Reports extends Component {



  state = {
    //usersloading: true
  }


  downloadReport = async (reportType) => {
    this.setState({[`${reportType}loading`]: true})
    const x = await apiCall('getReport', {reportType: reportType});
    this.setState({[reportType]: true, [`${reportType}link`]: x.downloadURL})
    this.setState({[`${reportType}loading`]: false})
  }


  downloadSection = (title, reportType) => {
    return (
      <div key={reportType} style={{height: 200, display: 'flex', flexDirection: 'column'}}>
        <Button style={{height: 50, width: 250}} variant="contained" onClick={()=>this.downloadReport(reportType)}>{this.state[`${reportType}loading`] === true ? <ClipLoader/> : title}</Button>
        <span style={{marginTop: 10 }}>{bigData[reportType]}</span>
        {this.state[reportType] === true &&
          <a style={{marginTop: 10, marginLeft: 10}} href={this.state[`${reportType}link`]}>Download Report</a>
        }

      </div>
    )
  }


  render() {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderStyle: 'solid', width: 600, minHeight: 600, borderRadius: 5, padding: 10, justifyContent: 'space-around' }}>
          {this.downloadSection('Generate Users Report', 'users')}
          {this.downloadSection('Generate Purchases Report', 'purchases')}
        </div>
      )
  }
}
