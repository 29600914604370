import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

/*
319.99px
180px
*/
/*
width: 319.99px;
height: 180px;
*/
const ImageWrapper = styled.div`
  position: relative;
  width: 319.99px;
  height: 180px;
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 319.99px;
  height: 180px;
  animation: ${loadingAnimation} 1s infinite;
  border-radius: 20px;
  -webkit-box-shadow: 10px 7px 20px -1px rgba(0,0,0,0.31);
  -moz-box-shadow: 10px 7px 20px -1px rgba(0,0,0,0.31);
  box-shadow: 10px 7px 20px -1px rgba(0,0,0,0.31);
`;


const StyledImage = styled.img`
  width: 319.99px;
  height: 180px;
  border-radius: 20px;
  -webkit-box-shadow: 10px 7px 20px -1px rgba(0,0,0,0.31);
  -moz-box-shadow: 10px 7px 20px -1px rgba(0,0,0,0.31);
  box-shadow: 10px 7px 20px -1px rgba(0,0,0,0.31);
`;

const LazyImage = ({ src, alt }) => {
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  return (
    <ImageWrapper>
      <Placeholder ref={refPlaceholder} />
      <LazyLoad offset={100} height={180}>
        <StyledImage
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={src}
          alt={alt}
        />
      </LazyLoad>
    </ImageWrapper>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default LazyImage;
