import { combineReducers } from 'redux';

import ReducerData from './reducerData';
import AuthUserReducer from './reducerAuthUser';

const rootReducer = combineReducers({
  reducerData: ReducerData,
  authUser: AuthUserReducer
});

export default rootReducer;