import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Form, FormGroup, Input } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import * as actions from 'Actions';

import { whiteLabel } from 'Whitelabel';

const introText = whiteLabel('introText');
const introTitle = whiteLabel('introTitle');
const highlightColour = whiteLabel('highlightColour');

class SignIn extends Component {

  state = {
     password: '',
  }

  /**
	 * On User Login
	 */
   async onUserLogin() {
      if (this.props.authEmail !== '' && this.state.password !== '') {
         this.props.saveAuthLoading(true)
         await this.props.signinUserInCognito(this.props.authEmail.toLowerCase(), this.state.password, this.props.history);
         //this.setState({ loading: false });
      }
   }

  renderSignLoad() {
    return (
    <div style={{ width: 200, backgroundColor: highlightColour, color: 'white', height: 45, borderRadius: 5, fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
     {this.renderSignLoadInner()}
    </div>
   );
  }

  resetPassword = () => {
    this.props.changeMainAuthPage('resetPassword')
  }

  renderSignLoadInner(){
     if(this.props.authLoading===true){
        return(
           <ClipLoader
            sizeUnit={"px"}
            size={25}
            color={'white'}
            loading
           />
        );
      }
      else{
          return('Sign In')
        }
  }

  _handleKeyDown = (e) => {
   if (e.key === 'Enter') {
     this.onUserLogin();
   }
 }

  render() {
    const { password } = this.state;
    return (
      <>
      <div className="session-head mb-30">
         <h2 className="font-weight-bold">{introTitle}</h2>
         <p className="mb-0"
         style={{ fontWeight: 200}}
         >{introText}</p>
      </div>
      <Form>
         <FormGroup className="has-wrapper">
            <Input
               type="mail"
               value={this.props.authEmail}
               name="user-mail"
               id="user-mail"
               className="has-input input-lg custom-admin-inputs"
               placeholder="Email"
               onChange={(event) => this.props.saveEmail(event.target.value)}
            />
            <span className="has-icon"><i className="ti-email"></i></span>
         </FormGroup>
         <FormGroup className="has-wrapper">
            <Input
               value={password}
               type="Password"
               name="user-pwd"
               id="pwd"
               className="has-input input-lg custom-admin-inputs"
               placeholder="Password"
               onKeyDown={this._handleKeyDown}
               onChange={(event) => this.setState({ password: event.target.value })}
               style={{ fontWeight: 200}}
            />
            <span className="has-icon"><i className="ti-lock"></i></span>
         </FormGroup>

         <FormGroup style={{display: 'flex', justifyContent: 'center'}} className="has-wrapper">
         <div style={{cursor: 'pointer', fontSize: 14, fontWeight: 200, color: 'rgba(0,0,0,0.4)'}} onClick={this.resetPassword}>Reset Password</div>
         </FormGroup>

         <FormGroup className="mb-15">
            <Button
               //color={"primary"}
               //className="btn-block text-white w-50"
               //variant="contained"
               disabled={this.props.authLoading}
               //size="large"
               onClick={() => this.onUserLogin()}
               style={{ fontWeight: 200}}
            >
               {this.renderSignLoad()}
          </Button>
         </FormGroup>
      </Form>

            {/* <p className="text-muted">By signing up you agree to the</p> */}
            {/*
            <p className="mb-0"><a href="/forgetpassword" className="text-muted">Forgot password?</a></p>
            */}
      </>
    )
}

}

// map state to props
const mapStateToProps = (data) => {
   const { user, loading, owner, authEmail, authLoading, authUserName, authCountry } = data.authUser;
   return { user, loading, owner, authEmail, authLoading, authUserName, authCountry }
}

export default connect(mapStateToProps, actions)(SignIn);
