import { configureStore } from './myStore';
import Amplify, { Storage, API } from 'aws-amplify';
import { whiteLabel } from 'Whitelabel';
//react-native-aws3
import { logoutUserFromCognito } from './actions';
const storeState = configureStore().getState();
const { owner } = storeState.authUser;
const { videoURL } = storeState.reducerData;

const awsConfig = whiteLabel('awsConfig');

Amplify.configure(awsConfig);


//Our generic API call
const API_Call = async (myInit, screenBlocker) => {
  //console.log('myInit', myInit.body.operation, myInit)
  let val = [];
  try {
    val = await API.post('MyAWSApi', '', myInit);

    //console.log('Returned data from API call: ', myInit.body.operation, val);

    if (val.errorType === 'NotAuthorizedException') {
      //console.log('need to log out and in and again');
      signOut();
    }

    if (val.errorMessage) {
      console.log('is an unhandled error - ' + myInit.body.operation + ':', val);
      return false;
    }

    //If sucess = false, this is our backend saying something wrong, display the message so user can give it in feedback when they contact us
    if (val.success === false) {
      console.log('is an error from success - ' + myInit.body.operation + ':', val);
      return false;
    }
    return val;
  } catch (e) {
    console.log('is an error from error', e);
    console.log('is an error from error', e.response);

    //signOut();
    return false;
  }
};



/*
----- S3 BUCKET FUNCTIONS ----
--- storage.list ---
*/
/*
export const storageList = async () => {


  console.log('In storageList call');

  const customPrefix = {
    public: `output/hls/${owner}`,
    protected: 'myProtectedPrefix/',
    private: 'myPrivatePrefix/'
};

  return new Promise((resolve) => {

  Storage.list('', {
    customPrefix: customPrefix,
    level: 'public',
  })
    .then(result => {
      console.log('result', result);
      //Filter for just m3u8 files and also if filename has / which means we are filtering out subdirectories
      const tmp = result.filter(function(item) {
       return item.key.substr(item.key.length-4, item.key.length) === 'm3u8' && (item.key.split("/").length - 1) === 1;
     });

     //get just the name and cut the first char from it as it's a /, also return an object as thats the way the table works
     const videoFileNames = tmp.map(x => ({ video: x.key.substr(1,x.key.length - 6) }));

     resolve(videoFileNames);

    })
    .catch(err => console.log(err));

  });

}
*/
/*
----- S3 BUCKET FUNCTIONS ----
*/

export const uploadVideoFile = async (e, uploadProgress) => {


  const customPrefix = {
    public: `input/${owner}/`,
    protected: 'myProtectedPrefix/',
    private: 'myPrivatePrefix/'
};

  const file = e.target.files[0];
  const regex = / /gi;
  let fileName = file.name.replace(regex, '_'); //replace all spaces with an underscore
  fileName = fileName.replace(/[^a-zA-Z0-9._]/g, ""); //remove anything thats not letter, number, fullstop or underscore;

  //await Storage.put(file.name, file, {
  await Storage.put(fileName, file, {
    progressCallback(progress) {
       uploadProgress(Math.round((progress.loaded/progress.total)*100));
  },
    customPrefix: customPrefix,
    level: 'public',
    //contentType: 'image/png'
  })
  .then (result => {
    //console.log(result);
    uploadProgress(0);
  })
  .catch(err => console.log(err));

}


export const uploadAttachment = async (e, uploadProgress, workout_uid) => {

  const customPrefix = {
      public: `output/hls/${owner}/attachments/`,
      protected: 'myProtectedPrefix/',
      private: 'myPrivatePrefix/'
  };
  const file = e.target.files[0];
  const regex = / /gi;
  let fileName = file.name.replace(regex, '_'); //replace all spaces with an underscore
  fileName = fileName.replace(/[^a-zA-Z0-9._]/g, ""); //remove anything thats not letter, number, fullstop or underscore;

  if (!(fileName.substr(-3).toLowerCase() === 'pdf')) {
    alert('Must be a pdf file');
    return;
  }

  fileName = new Date().getTime() + '-' + fileName;
  const r = await Storage.put(fileName, file, {
    progressCallback(progress) {
       uploadProgress(Math.round((progress.loaded/progress.total)*100));
  },
    customPrefix: customPrefix,
    level: 'public',
    //contentType: 'image/png'
  })
  .then (result => {
    return result;
    //console.log('sdafsdfdsf---', result)
  })
  .catch(err => {
    console.log(err)
    return false;
  });

  return r;
}


export const uploadThumbnailFile = async (e, uploadProgress, folders = false) => {

  //Setup params for upload
  const customPrefix = {
      public: folders ? `output/hls/${owner}/thumbnailsFolders/` : `output/hls/${owner}/thumbnails/`,
      protected: 'myProtectedPrefix/',
      private: 'myPrivatePrefix/'
  };

  //Remove any nasty characters from file name
  const file = e.target.files[0];
  const regex = / /gi;
  let fileName = file.name.replace(regex, '_'); //replace all spaces with an underscore
  fileName = fileName.replace(/[^a-zA-Z0-9._]/g, ""); //remove anything thats not letter, number, fullstop or underscore;

  //Check to make sure it's jpg
  if (!(fileName.substr(-3).toLowerCase() === 'jpg' || fileName.substr(-4).toLowerCase() === 'jpeg')) {
    alert('Image must be a jpg/jpeg file');
    return { ret: false };
  }

  //Add timestamp so name is unique
  fileName = new Date().getTime() + '-' + fileName;

  //Upload file
  await Storage.put(fileName, file, {
    progressCallback(progress) {
       uploadProgress(Math.round((progress.loaded/progress.total)*100));
  },
    customPrefix: customPrefix,
    level: 'public',
    //contentType: 'image/png'
  })
  .then (result => {
    //Once uploaded finish, check the image size
  })
  .catch(err => {
    console.log(err)
    return { ret: false };
  });

  //If we are here, then upload success, so check file size
  const ret = await getImageSize(fileName, folders);
  //uploadProgress(0);
  return ret
}


export const getImageSize = async (key, folders) => {

  const videoURL = localStorage.getItem('videoURL');

return new Promise(resolve => {

  const urlOfFile = folders ? `${videoURL}thumbnailsFolders/${key}` : `${videoURL}thumbnails/${key}`;

  var img = new Image();

  img.onload = async function (){
    var height = img.height;
    var width = img.width;

    const widthSize = folders ? 1280 : 960
    const heightSize = folders ? 720 : 540

    const msg = folders ? 'Image must be 1280 x 720 pixels' : 'Image must be 960 x 540 pixels'
    if (width !== widthSize || height !== heightSize) {
      alert(msg);
      await deleteThumbnail(key, folders);
      resolve({success: false});
    } else {
      resolve({success: true, key: key});
    }

  }

  img.src = urlOfFile;
  });

}

export const deleteAttachment = async (key) => {
  const customPrefix = {
      public: `output/hls/${owner}/attachments/`,
      protected: 'myProtectedPrefix/',
      private: 'myPrivatePrefix/'
  };

  await Storage.remove(key, { progressCallback(progress) {},
    customPrefix: customPrefix,
    level: 'public',
  })
  .then (result => {
    //console.log('deleted!');
  })
  .catch(err => console.log(err));
}

export const deleteThumbnail = async (key, folders) => {
  const customPrefix = {
      //public: `output/hls/${owner}/thumbnails/`,
      public: folders ? `output/hls/${owner}/thumbnailsFolders/` : `output/hls/${owner}/thumbnails/`,
      protected: 'myProtectedPrefix/',
      private: 'myPrivatePrefix/'
  };

  await Storage.remove(key, { progressCallback(progress) {},
    customPrefix: customPrefix,
    level: 'public',
  })
  .then (result => {
    //console.log('deleted!');
  })
  .catch(err => console.log(err));
}


/*
----- S3 BUCKET FUNCTIONS ----
----- storage.get ---

export const storageGet = async () => {
  console.log('In storage call');
  Storage.get('example.png')
    .then(result => console.log(result))
    .catch(err => console.log(err));
}
*/



/*
---------------------------------------------------------------
apiCall function
---------------------------------------------------------------
*/
export const apiCall = async (operation, data, screenBlocker = false) => {
  //First check if token is still good
  //If not replace with new tokens and save to redux
  /*
  let checkToken = await checkTokenExpiry(tokenData.expiry);
  if (checkToken.updateToken) {
      tokenData.accessToken = checkToken.token.accessToken;
      tokenData.idToken = checkToken.token.idToken;
      store.dispatch(saveTokens(checkToken.token));
  }
  */
  //Now we create out skeleton REST data

  let myInit = {
      headers: { auth_token: localStorage.getItem("auth_token") },
      body: {
        operation: operation,
        owner: owner,
        accessToken: localStorage.getItem("access_token"),
      },
  };

  switch (operation) {
    case 'getVimeoTracks':
      myInit.body.vimeo_id = data.vimeo_id;
      break;

    case 'getAllWorkouts':
      break;

    case 'getAllPrograms':
      break;

    case 'getUserData':
      myInit.body.showUnpurchasedItems = true;
      myInit.body.admin = data.admin;
      myInit.body.firstName = typeof data === "undefined" ? '' : data.firstName;
      myInit.body.country = typeof data === "undefined" ? '' : data.country;
      break;

    case 'saveRowOrder':
        myInit.body.items = data.items;
        break;

    case 'insertWorkout':
      myInit.body.vimeo_id = data.vimeo_id;
      myInit.body.uid = data.uid;
      myInit.body.date = data.date;
      myInit.body.descriptionD = data.description;
      myInit.body.intensity = data.intensity;
      myInit.body.equipment = data.equipment;
      myInit.body.duration = data.duration;
      myInit.body.trainer = data.trainer;
      myInit.body.title = data.title;
      myInit.body.tag = data.tag;
      myInit.body.store_sku = data.store_sku;
      myInit.body.type = data.type;
      myInit.body.link_id = data.link_id;
      myInit.body.draft = data.draft;
      myInit.body.portrait = data.portrait;
      myInit.body.timer = data.timer;
      myInit.body.tracking = data.tracking;
      myInit.body.col_order = data.col_order;
      myInit.body.video_key = data.video_key;
      myInit.body.image_key = data.image_key;
      myInit.body.attachment_key = data.attachment_key;
      myInit.body.level_1 = data.level_1;
      myInit.body.level_2 = data.level_2;
      myInit.body.level_3 = data.level_3;
      break;

    case 'insertProgram':
      myInit.body.sku = data.sku;
      myInit.body.date = data.date;
      myInit.body.uuid = data.uuid;
      myInit.body.workout_uid = data.workout_uid;
      myInit.body.position = data.position;
      break;

    case 'deleteWorkout':
      myInit.body.uid = data.uid;
      break;

    case 'checkRestrictedList':
      break;

    case 'readRestrictedList':
      break;

    case 'checkAndUpdatePurchases':
      break;

    case 'deleteRestrictedList':
      myInit.body.email = data.email;
      break;

    case 'writeRestrictedList':
      myInit.body.email = data.email;
      break;

    case 'pushNotify':
      myInit.body.title = data.title;
      myInit.body.message = data.message;
      break;

    case 'getVideoList':
      break;

    case 'readAllPurchases':
      break;

    case 'readAllCoupons':
      break;

    case 'deletePurchase':
      myInit.body.uuid = data.uuid;
      break;

    case 'deleteCoupon':
      myInit.body.coupon_code = data.coupon_code;
      break;

    case 'addPurchase':
      myInit.body.email = data.email;
      myInit.body.sku = data.sku;
      myInit.body.last_day = data.last_day;
      break;

    case 'addCoupon':
      myInit.body.coupon_code = data.coupon_code;
      myInit.body.coupon_type = data.coupon_type;
      myInit.body.created_on = data.created_on;
      myInit.body.expiry_date = data.expiry_date;
      myInit.body.limit_use_to = data.limit_use_to;
      break;

    case 'addAttachment':
       myInit.body.uid = data.uid;
       myInit.body.attachment_key = data.attachment_key;
       break;

    case 'addThumbnail':
      myInit.body.uid = data.uid;
      myInit.body.image_key = data.image_key;
      break;

    case 'editFolderImage':
      myInit.body.databaseKey = data.databaseKey;
      myInit.body.imageFileKey = data.imageFileKey;
      break;

    case 'addFolder':
      myInit.body.extraData = data;
      break;

    case 'deleteFolder':
      myInit.body.primaryKey = data.primaryKey;
      break;

    case 'saveFolderRowOrder':
      myInit.body.dbColumn = data.dbColumn;
      myInit.body.primaryKey = data.primaryKey;
      myInit.body.newOrder = data.newOrder;
      break;

    case 'saveMasterTrackingAdmin':
      myInit.body.rows = data.rows;
      myInit.body.weeks = data.weeks;
      myInit.body.w1 = data.w1;
      myInit.body.w2 = data.w2;
      myInit.body.w3 = data.w3;
      myInit.body.w4 = data.w4;
      myInit.body.w5 = data.w5;
      myInit.body.w6 = data.w6;
      myInit.body.w7 = data.w7;
      myInit.body.w8 = data.w8;
      myInit.body.w9 = data.w9;
      myInit.body.w10 = data.w10;
      myInit.body.w11 = data.w11;
      myInit.body.w12 = data.w12;
      myInit.body.w13 = data.w13;
      myInit.body.w14 = data.w14;
      myInit.body.exerciseUID = data.exerciseUID;
      break;

    case 'saveMasterTrackingAdminReps':
      myInit.body.w1 = data.w1;
      myInit.body.w2 = data.w2;
      myInit.body.w3 = data.w3;
      myInit.body.w4 = data.w4;
      myInit.body.w5 = data.w5;
      myInit.body.w6 = data.w6;
      myInit.body.w7 = data.w7;
      myInit.body.w8 = data.w8;
      myInit.body.w9 = data.w9;
      myInit.body.w10 = data.w10;
      myInit.body.w11 = data.w11;
      myInit.body.w12 = data.w12;
      myInit.body.w13 = data.w13;
      myInit.body.w14 = data.w14;
      myInit.body.exerciseUID = data.exerciseUID;
      break;

    case 'getLivestream':
      break;

    case 'updateLivestream':
      myInit.body.url = data.url;
      myInit.body.goinglive = data.goinglive;
      myInit.body.draft = data.draft;
      break;

    case 'getMasterTrackingAdmin':
      break;

    case 'getReport':
      myInit.body.reportType = data.reportType;
      break;

    case 'bulkPurchaseUpload':
      myInit.body.emails = data.emails;
      myInit.body.deleteAll = data.deleteAll;
      break;

    default:
      console.error('No operaton found for: ', operation);
      return false;
  }

  return await API_Call(myInit, screenBlocker);

};

export const signOut = () => {
  configureStore().dispatch(logoutUserFromCognito());
  window.location.reload(false); //Refresh the window so it takes us back to the main login page
  /*

  Auth.signOut({ global: true })
      .then()
      .catch(error => {
        //console.log('error', error);
        if (error.code === 'NotAuthorizedException') {
          //console.log('Sign out without global');
          Auth.signOut();
        }
      });
    */
};


/*
---------------------------------------------------------------
getSettings function
---------------------------------------------------------------
*/
//We do a get call to the API gateway and it's setup as mock in AWS API so it returns the settings data we have defined
//Resources -> GET -> Integration Response -> Open the 200 response -> Mapping Templates -> application/json
export const getSettings = async (versionLocal, upgradeLink, upgradeURL) => {
  try {
    let myInit = {'queryStringParameters': { 'scope': 'auk1sfdg6p841f198gjacml6894fasfq'}};
    const val = await API.get('MyAWSApi', '', myInit);
    return val;
/*
    //If here all is ok
    store.dispatch(saveUpgradeAvailable({ avail: patchOrMinorUpgrade, link: upgradeURL, version: versionLocal }));


    return { forceUpgrade: doWeForceUpgrade, maintenanceMode: maintenanceMode, restrictedList: restrictedList };
*/
  } catch (e) {
    console.log('error from get settings GET call: ', e);
  }
};
