import {
  SAVE_WORKOUTS,
  SAVE_PURCHASES,
  SAVE_ROW_ORDER,
  SAVE_VIDEO_URL,
  SAVE_WORKOUTS_ORIG,
  SAVE_PARSED_WORKOUTS,
  FILTER_ON,
  SAVE_FOLDERS,
} from '../actions/types';

const INIT_STATE = {
    videoURL: localStorage.getItem('videoURL'),
};


export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case SAVE_WORKOUTS:
      return { ...state, workouts: action.payload }
    case SAVE_WORKOUTS_ORIG:
      return { ...state, workoutsOrig: action.payload }
    case SAVE_PURCHASES:
       return { ...state, purchases: action.payload }
    case SAVE_ROW_ORDER:
       return { ...state, rowOrder: action.payload };
    case SAVE_VIDEO_URL:
      return { ...state, videoURL: action.payload };
    case SAVE_PARSED_WORKOUTS:
      return { ...state, parsedWorkouts: action.payload };
    case FILTER_ON:
      return { ...state, filterOn: action.payload };
    case SAVE_FOLDERS:
      return { ...state, folderData: action.payload };
    default:
      return state;
  }
}
