import React, { Component } from 'react';


import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import jwt from 'jsonwebtoken';
import { whiteLabel } from 'Whitelabel';
import { isMobile } from "react-device-detect";

import Layout from './screens/mainpage';
import AppSignIn from './screens/authscreen/mainAuthPage';
//import AdminPage from './screens/adminpanel';
import AdminPage from './screens/adminpanel/adminscreen';

//White labeling items
const headerBackground = whiteLabel('headerBackground');
const logo = whiteLabel('logo');

//added by oh7even
const isAuthenticated = () => {
	let accessToken = localStorage.getItem("access_token");
	if(!accessToken) return false;
	const {exp} = jwt.decode(accessToken);
	if(new Date().getTime() < (exp * 1000)) {
		return true;
	}
	localStorage.removeItem("user_id");
	localStorage.removeItem("access_token");
	return false;

 }


class App extends Component {

	adminRoutes() {
			if (this.props.admin) {
				return (
					<div>
					<Route path="/signin" component={AppSignIn} />
					<Route path="/admin" component={AdminPage} />
					</div>
				)
			} else {
				return (
					<Route path="/signin" component={AppSignIn} />
				)
			}
	}


  render() {
		if (!isMobile) {
	    const { location, match, user } = this.props;
	      if(location.pathname !== '/signup' && location.pathname !== "/forgetpassword") {
	         if(!isAuthenticated() && location.pathname !== '/signin') {
	            return (<Redirect to={'/signin'} />);
	         }
	         if (location.pathname === '/') {
	            if(user === null) {
	               return (<Redirect to={'/signin'} />);
	            }
	            return (
	               <Layout />
	            );
	         }
	      }
	      return (
	        //  <RctThemeProvider>
	        <div>
	           <NotificationContainer />
	           {/* <InitialPath
	              path={`${match.url}app`}
	              authUser={user}
	              component={RctDefaultLayout}
	           /> */}
						 {this.adminRoutes()}
						 {/*
	           <Route path="/signin" component={AppSignIn} />
						 <Route path="/admin" component={AdminPage} />
						 */}

             {/*
	           <Route path="/signup" component={AppSignUp} />
	           <Route path="/forgetpassword" component={AppForgetPassword} />
             */}
	        </div>
	        //  </RctThemeProvider>
	      );
			} else {
				return (
					<div
						style={{
							height: '100%',
							display: 'flex',
							flex: 1,
							//backgroundColor: 'red',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
              backgroundImage: `url(${headerBackground})`,
              backgroundSize: '200%',
						}}
							>

					<div style={{ backgroundColor: 'rgba(0,0,0,0.3)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

						<img src={logo} style={{ width: '50%', marginTop: 5 }} alt="app logo" />
						<span style={{ color: 'white', padding: 20,  height: 330, lineHeight: '26px'}}>{this.props.owner} On Demand is a web based portal to watch all your {this.props.owner} videos on your computer. Please visit this site from your computer to continue. If you wish to watch on your mobile please download the {this.props.owner} mobile app.</span>
					</div>
				{/*
        	<img src={AppStore} style={{ width: '50%', marginTop: 30}} alt="apppleStore" />
					<img src={AndroidStore} style={{ width: '50%', marginTop: 20, marginBottom: 100}} alt="androidStore" />
				*/}
        	</div>
				);
			}

  	}//end of render()

}//end of component



// map state to props
const mapStateToProps = ({ authUser }) => {
  const { user, owner, admin } = authUser;
  return { user, owner, admin };
};

export default connect(mapStateToProps)(App);
