import React from 'react';
import ReactDOM from 'react-dom';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';


//import store from '../myStore';
import { configureStore } from './myStore'

import App from './app';

// Import main sass file to apply global styles
import 'Assets/sass/style.scss';

// const createStoreWithMiddleware = createStore(reducers, undefined, compose(applyMiddleware(Thunk)));

const app = (
   <Provider store={configureStore()}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
			<Router>
				<Switch>
					<Route path="/" component={App} />

				</Switch>
			</Router>
		</MuiPickersUtilsProvider>
   </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
