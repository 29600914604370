import React from 'react';
import DropdownArrow from 'Assets/images/drop-down-arrow.svg';
import { NavLink } from 'react-router-dom';

import { whiteLabel } from 'Whitelabel';

const highlightColour = whiteLabel('highlightColour');

const dropdownContent = (props) => (

  <div className="dropdownContainer" style={{ marginRight: 30 }}>
    <div className="navigation__container--userLogo" style={{ backgroundColor: highlightColour, paddingTop: 8}}>
      <DropdownArrow style={{ marginTop: 10, marginLeft: 16, fill: 'white', display: 'flex' }} className="navigation__container--downArrow" />
      <div className="dropdownContent dropdownContent--2" style={{ marginTop: -50, height: props.admin ? '8rem' : '5rem'}}>
        {props.admin &&
          <NavLink to="/admin" activeClassName="dropdownContent-textOutside" style={{ fontSize: '1.2rem', color: 'white', marginBottom: 20 }} >Admin Panel</NavLink>
        }
        <p onClick={()=>props.signOut()} className="dropdownContent-textOutside">Sign out</p>
      </div>
    </div>
  </div>
);

export default dropdownContent;
