import { createStore, applyMiddleware, compose } from 'redux';
import Thunk from 'redux-thunk';
import reducers from './reducers';


export function configureStore() {

  const initialState = {
    reducerData: {
      purchases: ['']
    },
   };

    const store = createStore(
        reducers,
        initialState,
        compose(applyMiddleware(Thunk))
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers/index', () => {
            const nextRootReducer = require('./reducers/index');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}


/*
const initialState = { };

const store = createStore(reducers, initialState, compose(applyMiddleware(Thunk)));

export default store;

*/
