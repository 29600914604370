import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Form, FormGroup, Input } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import * as actions from 'Actions';
import Amplify, { Auth } from 'aws-amplify';
import { whiteLabel } from 'Whitelabel';
import { apiCall } from 'CommonFunctions';

const awsConfig = whiteLabel('awsConfig');
const introTitle = whiteLabel('introTitle');
const highlightColour = whiteLabel('highlightColour');

Amplify.configure(awsConfig);

class ConfirmEmail extends Component {

  constructor(props) {
    super(props);
    this.state = {
       code: '',
       resendLoading: false
     }
    }

  /**
	 * On User Login
	 */
   async onUserConfirmEmail() {
     this.props.saveAuthLoading(true)
     Auth.confirmSignUp(this.props.authEmail, this.state.code, { forceAliasCreation: true })
      .then(async data => {
        const r = window.alert('Thank you... you can now sign in!');
        this.props.saveAuthLoading(false)
        this.props.changeMainAuthPage('signin')
        }
      )
      .catch(err => {
        console.log('Error from verify code ', err);
        const r = window.alert(err.message);
        this.props.saveAuthLoading(false)
      });
   }

  resendCode = () => {
    this.setState({resendLoading: true});

    Auth.resendSignUp(this.props.authEmail)
    .then(success => {
        const r = window.alert('New email verify code sent')
        //dispatch({ type: CHANGE_AUTH_PAGE, payload: 'confirmEmail'});
        this.props.saveAuthLoading(false)
        this.setState({resendLoading: false});
    })
    .catch(error => {
      console.log('error', error);
      const r = window.alert(error.message)
      this.props.saveAuthLoading(false)
      this.setState({resendLoading: false});
    });
  }

  renderSignLoad() {
    return (
    <div style={{ width: 200, backgroundColor: highlightColour, color: 'white', height: 45, borderRadius: 5, fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
     {this.renderSignLoadInner()}
    </div>
   );
  }

  renderSignLoadInner(){
     if(this.props.authLoading===true){
        return(
           <ClipLoader
            sizeUnit={"px"}
            size={25}
            color={'white'}
            loading
           />
        );
      }
      else{
          return('Confirm Email')
        }
  }

  render() {
    return (
      <>
      <div className="session-head mb-30">
         <h2 className="font-weight-bold">Confirm your email</h2>
      </div>
      <Form>
         <FormGroup className="has-wrapper">
            <Input
               type="mail"
               value={this.props.authEmail}
               disabled
               name="user-mail"
               id="user-mail"
               className="has-input input-lg custom-admin-inputs"
               placeholder="Email"
               //onChange={(event) => this.setState({ email: event.target.value })}
            />
            <span className="has-icon"><i className="ti-email"></i></span>
         </FormGroup>
         <FormGroup className="has-wrapper">
            <Input
               value={this.state.code}
               //type="Password"
               //name="user-pwd"
               //id="pwd"
               className="has-input input-lg custom-admin-inputs"
               placeholder="Code"
               onKeyDown={this._handleKeyDown}
               onChange={(event) => this.setState({ code: event.target.value })}
            />
            <span className="has-icon"><i className="ti-lock"></i></span>
         </FormGroup>

         <FormGroup style={{display: 'flex', justifyContent: 'center'}} className="has-wrapper">
         <div style={{cursor: 'pointer'}} onClick={this.resendCode}>{this.state.resendLoading === false ? 'Resend Code' : <ClipLoader/>}</div>
         </FormGroup>

         <FormGroup className="mb-15">
            <Button
               //color={"primary"}
               //className="btn-block text-white w-50"
               //variant="contained"
               disabled={this.props.authLoading}
               //size="large"
               onClick={() => this.onUserConfirmEmail()}
               style={{ fontWeight: 200}}
            >
               {this.renderSignLoad()}
          </Button>
         </FormGroup>

      </Form>
      </>
    )
}

}

// map state to props
const mapStateToProps = (data) => {
   const { user, loading, owner, authEmail, authLoading, authUserName, authCountry } = data.authUser;
   return { user, loading, owner, authEmail, authLoading, authUserName, authCountry }
}

export default connect(mapStateToProps, actions)(ConfirmEmail);
