import React, { Component } from 'react';
import { Button, Input, Label } from 'reactstrap';
import { whiteLabel } from 'Whitelabel';
import { apiCall } from 'CommonFunctions';
import LoadingSpinner from 'Assets/images/loading.gif';

//const liveStream = whiteLabel('liveStream');

export default class Streaming extends Component {

  state = {
    liveStreamAddress: '',
    goingLive: 0,
    draft: 0
  }


  async componentWillMount() {
      const x = await apiCall('getLivestream');
      this.setState({ goingLive: x.results.goinglive, liveStreamAddress: x.results.url, draft: x.results.draft})
  }

  liveIndication = () => {
    return (
      <div style={{
          color: this.state.goingLive === 1 && this.state.draft === 0 ? 'red' : this.state.goingLive === 1 && this.state.draft === 1 ? 'orange' : 'blue',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: 100,
          height: 100,
          borderRadius: 50,
          borderWidth: 5,
          borderColor: this.state.goingLive === 1 && this.state.draft === 0 ? 'red' : this.state.goingLive === 1 && this.state.draft === 1 ? 'orange' : 'blue',
          borderStyle: 'solid'}}>
        {this.state.goingLive === 1 && this.state.draft === 0 && 'YOU ARE LIVE!'}
        {this.state.goingLive === 1 && this.state.draft === 1 && 'YOU ARE streaming in draft mode'}
        {this.state.goingLive === 0 && 'Offline'}
      </div>
    )
  }

  funcUploadAttachment = async (evt) => {

  }


  goLiveButton = async () => {

    const draft = this.state.draft;

    //This turns live off if we are already live
    if (this.state.goingLive === 1) {
      const x = await apiCall('updateLivestream', {url: this.state.liveStreamAddress, goinglive: 0, draft: draft});
      if (x.success === true) {
        this.setState({ goingLive: 0 });
      }
    } else {
      //Else we must be turning live on
      //Some basic error checking
      if ((this.state.liveStreamAddress.substring(0,5) === 'http:' || this.state.liveStreamAddress.substring(0,6) === 'https:') && this.state.liveStreamAddress.substring(this.state.liveStreamAddress.length - 4) === 'm3u8') {
        const x2 = await apiCall('updateLivestream', {url: this.state.liveStreamAddress, goinglive: 1, draft: draft});
        if (x2.success === true) {
          this.setState({ goingLive: 1 });
        }
      } else {
        alert('URL is incorrect format.')
        return;
      }
    }

  }

  render() {
    return (
      <div style={{ borderWidth: 1, borderRadius: 5, padding: 5, width: 850, borderStyle: 'solid'}}>
      Live Stream Controls
        <div style={{ display: 'flex', alignItems: 'center'}}>

          {this.liveIndication()}

          <div
            style={{cursor: 'pointer', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', height: 50, width: 100, backgroundColor: this.state.goingLive === 1 && this.state.draft === 0 ? 'red' : this.state.goingLive === 1 && this.state.draft === 1 ? 'orange' : 'green', marginLeft: 10}}
            onClick={()=>this.goLiveButton()}
          >
            {this.state.goingLive === 1 && this.state.draft === 0 && 'Stop Live'}
            {this.state.goingLive === 1 && this.state.draft === 1 && 'Stop Draft'}
            {this.state.goingLive === 0 && 'Start'}
          </div>

          <div style={{ marginLeft: 10 }}>
            <Input
                onChange={(e) => this.setState({ liveStreamAddress: e.target.value})}
                style={{ fontSize: 12, height: 20, width: 550 }}
                autoComplete='none'
                disabled = {this.state.goingLive === 1 ? true : false}
                value={this.state.liveStreamAddress}
            />
            <div style={{ marginTop: 5, marginLeft: 20}}>


              <Label check>
              <Input
                  onChange={(e)=>this.setState({ draft: e.target.checked ? 1 : 0})}
                  type="checkbox"
                  disabled = {this.state.goingLive === 1 ? true : false}
                  checked={this.state.draft === 1}
              />{' '}
               Draft
             </Label>


            </div>
          </div>


        </div>
      </div>
    )
  }

}
