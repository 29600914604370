import {
   SAVE_WORKOUTS,
   SAVE_PURCHASES,
   SAVE_ROW_ORDER,
   SAVE_VIDEO_URL,
   SAVE_WORKOUTS_ORIG,
   SAVE_PARSED_WORKOUTS,
   FILTER_ON,
   SAVE_FOLDERS,
} from './types';

export const saveWorkouts = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_WORKOUTS,
      payload: data
    });
}

export const saveWorkoutsOrig = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_WORKOUTS_ORIG,
      payload: data
    });
}

export const savePurchases = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_PURCHASES,
      payload: data
    });
}

export const saveRowOrder = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_ROW_ORDER,
      payload: data
    });
}

export const saveVideoURL = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_VIDEO_URL,
      payload: data
    });
}

export const saveParsedWorkouts = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_PARSED_WORKOUTS,
      payload: data
    });
}

export const filterOn = (data) => async (dispatch) => {
    dispatch({
      type: FILTER_ON,
      payload: data
    });
}

export const saveFolderData = (data) => async (dispatch) => {
    dispatch({
      type: SAVE_FOLDERS,
      payload: data
    });
}
