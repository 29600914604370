export const awsConfig_isofit = {
  Auth: {
    identityPoolId: 'ap-southeast-2:994f08b0-25df-4882-844a-131cd3f49acd',
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_vrE2SUyeq',
    userPoolWebClientId: '4jto2k0f6ikuc012p9bnk665dq',
  },
    API: {
        endpoints: [
            {
                name: 'MyAWSApi',
                endpoint: 'https://4vebjgjzr5.execute-api.ap-southeast-2.amazonaws.com/Prod/',
             },
         ],
     },
     Storage: {
         AWSS3: {
             bucket: 'vod-2-746294054071-us-east-1', //REQUIRED -  Amazon S3 bucket
             region: 'us-east-1', //OPTIONAL -  Amazon service region
         }
     }
};

export const awsConfig_isofit_dev = {
  Auth: {
    identityPoolId: 'ap-southeast-2:4f2c011e-bdbd-48f4-b6a4-3a0d1b9337ff',
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_tQznvf75Z',
    userPoolWebClientId: '2r8e85sc3ravact0qs4up252a5',
  },
    API: {
         endpoints: [
             {
                 name: 'MyAWSApi',
                 endpoint: 'https://4vebjgjzr5.execute-api.ap-southeast-2.amazonaws.com/Prod/dev',
             },
         ],
     },
     Storage: {
         AWSS3: {
             bucket: 'vod-2-746294054071-us-east-1', //REQUIRED -  Amazon S3 bucket
             region: 'us-east-1', //OPTIONAL -  Amazon service region
         }
     }
};


export const awsConfig_netfit = {
  Auth: {
    identityPoolId: 'ap-southeast-2:c2a181ec-65c1-45f3-9f0a-efcee675ed92',
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_qj7jfxXgf',
    userPoolWebClientId: 'nk2h9b7s0auqqi4484osdme2d',
  },
    API: {
         endpoints: [
             {
                 name: 'MyAWSApi',
                 endpoint: 'https://4vebjgjzr5.execute-api.ap-southeast-2.amazonaws.com/Prod/netfit',
             },
         ],
     },
     Storage: {
         AWSS3: {
             bucket: 'vod-2-746294054071-us-east-1', //REQUIRED -  Amazon S3 bucket
             region: 'us-east-1', //OPTIONAL -  Amazon service region
         }
     }
};

export const awsConfig_netfitnz = {
  Auth: {
    identityPoolId: 'ap-southeast-2:8a5b75db-b823-432d-a8b3-b379a769d449',
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_LEfGs8n8D',
    userPoolWebClientId: 'g6mk9q96l6240cfbuvnsl7ipu',
  },
    API: {
         endpoints: [
             {
                 name: 'MyAWSApi',
                 endpoint: 'https://4vebjgjzr5.execute-api.ap-southeast-2.amazonaws.com/Prod/netfitnz',
             },
         ],
     },
     Storage: {
         AWSS3: {
             bucket: 'vod-2-746294054071-us-east-1', //REQUIRED -  Amazon S3 bucket
             region: 'us-east-1', //OPTIONAL -  Amazon service region
         }
     }
};


export const awsConfig_recx = {
  Auth: {
    identityPoolId: 'ap-southeast-2:3d0d66ca-8af3-4419-be2e-a9a9bb167839',
    region: 'ap-southeast-2',//
    userPoolId: 'ap-southeast-2_JvJM9Qiyc',//
    userPoolWebClientId: '424ipuvilpsqgt91dvmf0lojve',
  },
    API: {
         endpoints: [
             {
                 name: 'MyAWSApi',
                 endpoint: 'https://4vebjgjzr5.execute-api.ap-southeast-2.amazonaws.com/Prod/recx',
             },
         ],
     },
     Storage: {
         AWSS3: {
             bucket: 'vod-2-746294054071-us-east-1', //REQUIRED -  Amazon S3 bucket
             region: 'us-east-1', //OPTIONAL -  Amazon service region
         }
     }
};

export const awsConfig_britt = {
  Auth: {
    identityPoolId: 'ap-southeast-2:50063055-04c5-4d42-839a-b868da5592ba',
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_hIoy1jqL7',
    userPoolWebClientId: 'avrjo9upi6bnijpj28gto3t1u',
  },
    API: {
         endpoints: [
             {
                 name: 'MyAWSApi',
                 endpoint: 'https://4vebjgjzr5.execute-api.ap-southeast-2.amazonaws.com/Prod/britt',
             },
         ],
     },
     Storage: {
         AWSS3: {
             bucket: 'vod-2-746294054071-us-east-1',
             region: 'us-east-1',
         }
     }
};

export const awsConfig_strong = {
  Auth: {
    identityPoolId: 'ap-southeast-2:30a12152-4740-4ea5-98e3-0f4b6dffbb88',
    region: 'ap-southeast-2',//
    userPoolId: 'ap-southeast-2_SJUDRHAWW',//
    userPoolWebClientId: '1pfaf1m5b5kblegq74o0nl8una',//
  },
    API: {
         endpoints: [
             {
                 name: 'MyAWSApi',
                 endpoint: 'https://4vebjgjzr5.execute-api.ap-southeast-2.amazonaws.com/Prod/strong',
             },
         ],
     },
     Storage: {
         AWSS3: {
             bucket: 'vod-2-746294054071-us-east-1', //REQUIRED -  Amazon S3 bucket
             region: 'us-east-1', //OPTIONAL -  Amazon service region
         }
     }
};
