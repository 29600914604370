
import React, { Component } from 'react';
import moment from 'moment';
import { CSVReader } from 'react-papaparse';
import { apiCall } from 'CommonFunctions';

const buttonRef = React.createRef()

export default class BulkUpload extends Component {

  state = {
    data: [],
    fileUploaded: false,
    errors: false,
    loading: false,
    dataLength: false
  }

  handleOpenDialog = (e) => {
      // Note that the ref is set async, so it might be null at some point
      if (buttonRef.current) {
        buttonRef.current.open(e)
      }
    }

    checkExpiryString = (string) => {
      return (string.length === 10 && moment(string).isValid()) ? 1 : 0
    }

    isEmailValid = (email) => {
        var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (email !== '' && email.match(emailFormat)) { return true; }
        return false;
    }


    handleOnFileLoad = (data) => {
      function compare( a, b ) {
        if ( a.valid < b.valid ){
          return -1;
        }
        if ( a.valid > b.valid ){
          return 1;
        }
        return 0;
      }


      const dataParsed1 = data.map(item => item.data)

      const dataParsed2 = dataParsed1.map(item => {
        const d = {
          email: item[0].trim(),
          expiry: item[1],
          valid: this.checkExpiryString(item[1]) && this.isEmailValid(item[0].trim()) ? 1 : 0
        }
        return d
      })

      dataParsed2.sort( compare );

      const errors = dataParsed2.map(item => item.valid).filter(item => item === 0)
      this.setState({data: dataParsed2, fileUploaded: true, errors: errors.length > 0, dataLength: dataParsed1.length})
    }

    handleOnError = (err, file, inputElem, reason) => {
      console.log(err)
    }


    handleSaveData = async () => {
      this.setState({loading: true})
      await apiCall('bulkPurchaseUpload', {emails:this.state.data, deleteAll: 'false'})
      await window.confirm('Finished Uploading');

        this.setState({
            data: [],
            fileUploaded: false,
            errors: false,
            loading: false,
            dataLength: false
          })

      this.props.reloadPurchases();

    }
/*
//Too dangerous, not needed
    handleSaveDataDeleteAll = async () => {
      const r = window.confirm('This will delete all current purchases and upload below items?');
      if(r === true)
      {
        await apiCall('bulkPurchaseUpload', {emails:this.state.data, deleteAll: 'true'});
        await window.confirm('Finished Uploading');
          this.setState({
              data: [],
              fileUploaded: false,
              errors: false,
              loading: false,
              dataLength: false
            })
        this.props.reloadPurchases();
      }
    }
*/
    displayDataTable = () => {

      return (
        <>
        <div style={{ display: 'flex', flexDirection: 'row', minWidth: 400, paddingTop: 10, paddingBottom: 0, fontWeight: 600 }}>
          <div style={{width: 300, fontSize: 12}}>Email</div>
          <div style={{width: 75, fontSize: 12, textAlign: 'center'}}>Expiry</div>
          <div style={{width: 75, fontSize: 12, textAlign: 'center'}}>Valid</div>
        </div>
        <div style={{ height: 500, overflow: 'scroll'}}>
        {this.state.data.map((item, index) => {
          return (
            <div
            //onClick={() => this.removePurchase(item.uuid)}
              key={index}
              style={{ display: 'flex', flexDirection: 'row', minWidth: 400, paddingTop: 10, paddingBottom: 10, borderTopWidth: 0.5, borderTopColor: 'rgba(0,0,0,0.25)', borderTopStyle: 'solid'}}>
              <div style={{width: 300, fontSize: 12}}>{item.email}</div>
              <div style={{width: 75, fontSize: 12, textAlign: 'center'}}>{item.expiry}</div>
              <div style={{width: 75, fontSize: 12, textAlign: 'center'}}>{item.valid}</div>
            </div>
          )
        })}
        </div>
        </>
      );
    }


  render() {
    return (
      <div style={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 5, padding: 10, marginRight: 20, marginBottom: 100, width: 500 }}>
      <span style={{ marginBottom: 5 }}>Bulk Purchase upload</span>
      <CSVReader
         ref={buttonRef}
         onFileLoad={this.handleOnFileLoad}
         onError={this.handleOnError}
         noClick
         noDrag
         //onRemoveFile={this.handleOnRemoveFile}
       >
         {({ file }) => (
           <aside
             style={{
               display: 'flex',
               flexDirection: 'row',
               marginBottom: 10
             }}
           >
             <button
               type='button'
               onClick={this.handleOpenDialog}
               disabled={this.state.loading}
               style={{
                 borderRadius: 0,
                 marginLeft: 0,
                 marginRight: 0,
                 width: '40%',
                 paddingLeft: 0,
                 paddingRight: 0
               }}
             >
               Browse file
             </button>

             {this.state.fileUploaded &&
               <button
                 type='button'
                 disabled={this.state.errors || this.state.loading}
                 onClick={this.handleSaveData}
                 style={{
                    marginLeft: 20,
                    height: 40,
                    width: 100,
                 }}
               >
                 Save
               </button>
             }

             {  /*
               this.state.fileUploaded &&
               <button
                 type='button'
                 disabled={this.state.errors || this.state.loading}
                 onClick={this.handleSaveDataDeleteAll}
                 style={{
                    marginLeft: 20,
                    height: 40,
                    width: 100,
                 }}
               >
                 Delete All & Save
               </button>
               */
             }

           </aside>
         )}
       </CSVReader>
       {this.state.errors &&
         <div style={{color: 'red'}}>You have some errors in the data that must be fixed</div>
       }
       {this.state.dataLength &&
         <div style={{color: 'blue'}}>Items: {this.state.dataLength}</div>
       }
       {this.displayDataTable()}
      </div>
    )
  }

}
