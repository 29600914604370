/**
 * Auth User Reducers
 */
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    SAVE_ADMIN,
    CHANGE_AUTH_PAGE,
    SAVE_EMAIL,
    LOADING_SPINNER,
    SAVE_USER_NAME,
    SAVE_COUNTRY,
} from '../actions/types';

import AppConfig from 'static/AppConfig';

/**
 * initial auth user
 */
const INIT_STATE = {
    owner: AppConfig.whitelabel,
    user: localStorage.getItem('user_id'),
    loading: false,
    confirm: false,
    admin: (localStorage.getItem('admin') === undefined || localStorage.getItem('admin') === "false") ? false : true,
    profileConfirmed: (localStorage.getItem('profile_confirmed') === undefined || localStorage.getItem('profile_confirmed') === "false") ? false : true,
    authScreen: 'signin',
    authEmail: '',
    authLoading: false,
    //authUserName: ''
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SAVE_ADMIN:
            return { ...state, admin: action.payload}

        case LOGIN_USER:
            return { ...state, loading: true };

        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };

        case LOGIN_USER_FAILURE:
            return { ...state, loading: false };

        case LOGOUT_USER:
            return { ...state, user: null };

        case CHANGE_AUTH_PAGE:
            return { ...state, authScreen: action.payload };

        case SAVE_EMAIL:
            return { ...state, authEmail: action.payload };

        case LOADING_SPINNER:
          return  { ...state, authLoading: action.payload };

        case SAVE_USER_NAME:
          return  { ...state, authUserName: action.payload };

        case SAVE_COUNTRY:
          return  { ...state, authCountry: action.payload };

        default: return { ...state };
    }
}
