import React from 'react';

const footer = () => (
  
   <footer className="footer">
      <div className="footer__copyright">
      &copy; 2020</div>
   </footer>
);

export default footer; 