import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { apiCall } from 'CommonFunctions';
import { TableRestrictedList } from '../components/tableRestrictedList.js';



export default class RestrictedList extends Component {

  state = {
    restrictedList: [],
    origRestrictedList: [],
    inputUserEmailAdd: [],
  }

  addUser = async () => {
    await apiCall('writeRestrictedList', { email: this.state.inputUserEmailAdd });
    await this.loadRestrictedList();
    this.setState({ inputUserEmailAdd: ''});
  }

  async loadRestrictedList() {
    const ret = await apiCall('readRestrictedList');
    this.setState({ origRestrictedList: ret.list, restrictedList: ret.list });
  }

  async componentWillMount() {
    await this.loadRestrictedList();
  }


  filterBox = (label, filterBy, listState, origListState) => {
    return (
      <div style={{ width: 300, height: 70, padding: 10, marginBottom: 10 }}>
      <span style={{ fontSize: 13 }}>{label}</span>
      <Input
          onChange={(e) => this.filterList(e.target.value, filterBy, listState, origListState)}
          style={{ fontSize: 12 }}
          autoComplete='none'
      />
      </div>
    );
  }

  filterList(text, filterBy, listState, origListState) {
    const tmp = this.state[origListState].filter(function(item) {
     return item[filterBy].toLowerCase().search(text.toLowerCase()) >= 0;
   });
   //If input is empty then set state back to full list
   this.setState({ [listState]: text === '' ? this.state[origListState] : tmp });
  }

  restrictedRowPressed = async (email) => {
    const r = window.confirm('Do you really want to delete this user?');
    if(r === true)
    {
      await apiCall('deleteRestrictedList', { email: email });
      await this.loadRestrictedList();
      this.setState({ inputUserEmailFilter: '' });
    }
  }

  render() {

      return (
        <div style={{ width: 550, borderWidth: 1, borderStyle: 'solid', borderRadius: 5, padding: 10 }}>
          <span style={{ marginBottom: 5 }}>Email List (Only these emails will be able to log in)</span>
          <div style={{ display: 'flex' }}>
            <Button variant="contained" color="primary" style={{marginBottom: 5}} onClick={()=>this.addUser()}>Add Email</Button>
            <Input
                onChange={(e) => this.setState({ inputUserEmailAdd: e.target.value })}
                style={{ width: 400, marginLeft: 10, fontSize: 12 }}
                value={this.state.inputUserEmailAdd}
                autoComplete='none'
            />
          </div>
          {this.filterBox('Search Email', 'email', 'restrictedList', 'origRestrictedList', 'inputUserEmailFilter')}
          <span style={{ fontSize: 12 }}>Click on an email to delete</span>
          <TableRestrictedList data={this.state.restrictedList} rowPressed={this.restrictedRowPressed}/>
        </div>
      )
  }
}
