import React, { Component } from 'react'
import LazyLoad from 'react-lazyload';
import LazyImage from "./LazyImage";

import Modal from 'Components/UI/Modal';

export default class SingleMovie extends Component {

   render() {
      let netflixUrl = false;
      let isLocked = this.props.movie.state === 'locked' || this.props.movie.state === 'not_paid_for'
      return (
         <>

            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 40, height: 275 }}
                 onClick={() => (
                   isLocked ?   alert('You don’t have access to this content. Upgrade your plan today via the mobile app.') : this.props.handleToggleModal(this.props.movie)
                 )}
                 className={`${this.props.movie.uid}` + " movieShowcase__container--movie" + (netflixUrl ? "__netflix" : "")}
            >
  {/*
            <LazyImage
                src={this.props.movie.thumbnailUrl}
                alt={`Random image ${5}`}
              />

  */}

                 <img
                  src={this.props.movie.thumbnailUrl}
                  alt={'image_alt'}
                  style={{
                    borderRadius: 20,
                    WebkitBoxShadow: '10px 7px 20px -1px rgba(0,0,0,0.31)',
                    MozBoxShadow: '10px 7px 20px -1px rgba(0,0,0,0.31)',
                    boxShadow: '10px 7px 20px -1px rgba(0,0,0,0.31)',
                    //opacity: 0.5,
                    width: 319.99,
                    height: 180,
                    opacity: isLocked ? 0.4 : 1
                   }}
                  className="movieShowcase__container--movie-image" />

              <a style={{ backgroundColor: 'rgba(0,0,0,0)', marginTop: 20, marginLeft: 22, color: 'rgba(0,0,0,0.7)', fontWeight: '700', opacity: isLocked ? 0.4 : 1}}>{this.props.movie.title}</a>

              {this.props.movie.draft === 1 && <a style={{marginTop: 5, backgroundColor: 'yellow', fontSize: 10, width: 140}}>DRAFT (only admin can see)</a>}

              <div style={{  backgroundColor: 'white', marginLeft: 20 }}>
                <a style={{ color: 'rgba(0,0,0,0.4)', fontSize: 12 }}>{this.props.movie.duration}</a>
                <a style={{ borderWidth: 1, backgroundColor: 'rgba(0,0,0,0.2)', fontSize: 12, height: 10, width: 1, marginLeft: 10, marginRight: 10 }}/>
                <a style={{ color: 'rgba(0,0,0,0.4)', fontSize: 12 }}>{this.props.movie.intensity}</a>
              </div>

            </div>

         </>
      )
   }
}
