import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import Auxx from '../../hoc/Auxx';
import PlayIcon from 'Assets/images/play-button.svg';
import LoadingSpinner from 'Assets/images/loading.gif';
import { apiCall } from 'CommonFunctions';
import { whiteLabel } from 'Whitelabel';


//modal example
//https://codesandbox.io/s/peaceful-bardeen-7jexx?from-embed=&file=/src/index.js
//pdf
//https://github.com/wojtekmaj/react-pdf

const highlightColour = whiteLabel('highlightColour');

async function playPressed(props) {
  const data = props.movie;

    if (props.movie.type === 'purchasable-no_movie') {
      alert('There is no video here, but you have already unlocked this series. You are free to watch all the movies in this series.');
      return false;
    }

  try {
    //Set state to loading
    props.changeLoadingVideoCallState(true);

    //If we have a hlsAddress use that, else we are still using the vimeo ID
    const hlsAddress = data.hlsAddress === '-' ?  await apiCall('getVimeoTracks', {vimeo_id: data.vimeo_id}) : {hls: data.hlsAddress}; //Do this until we fix up the return of getVimeoTracks
    //Set loading to false
    props.changeLoadingVideoCallState(false);


    //Start video (pass it the HLS file to play)
    props.startVideo(hlsAddress.hls);
  } catch (error) {
    console.log('error from api:', error);
    props.changeLoadingVideoCallState(false);
  }
}

export default function MovieDetails(props) {

  //console.log('---- Here 3 ---- ', localStorage.getItem('videoURL'))
  //console.log(props.movie.videoURL + 'attachments/' + props.movie.attachment_key)

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isShown, setIsShown] = useState(false);
  const [scale, setScale] = useState(1);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setScale(0.8);//At 0.8 we get it all in on an macbook pro screen
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Auxx>
      <div className="movie-modal__container">
        <h1 className="movie-modal__title">
          {props.movie.title || props.movie.name}
        </h1>
        <p className="movie-modal__episode" style={{ fontSize: 14, fontWeight: '300', maxWidth: 700, whiteSpace: 'pre-wrap', color: '#444444' }} dangerouslySetInnerHTML={{ __html: props.movie.description }} />
      <div>

      </div>
        <button
          onClick={() => props.closeModal()}
          className="movie-modal__btn"
          style={{color:'black'}}
          >
          Back
        </button>

        <button
          onClick={()=>playPressed(props)}
          className="movie-modal__btn movie-modal__btn"
          style={{ width: '8rem', backgroundColor: highlightColour, borderWidth: 0}}
        >

        {!props.loadingVideoCallState &&
          <div>
          <PlayIcon style={{ width: '2.5rem' }} className="movie-modal__btn--icon" />
          Play
          </div>
        }

          {props.loadingVideoCallState &&
            <img src={LoadingSpinner} style={{ width: '2rem'}} alt="loading..." />
            }

        </button>

        {props.movie.attachment_key !== '-' &&
          <>
            <button
              onClick={() => props.togglePDFViewer(true)}
              className="movie-modal__btn"
              style={{color:'black'}}
              >
              View Attachment
            </button>
            <a href={localStorage.getItem('videoURL') + 'attachments/' + props.movie.attachment_key} download>
              <button
                className="movie-modal__btn"
                style={{color:'black'}}
                >
                Download Attachment
              </button>
            </a>
          </>
        }

        {/*{isModalOpen && (*/}
        {props.isPDFOpen && (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, overflowY: 'scroll' }}>
          <div
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
            style={{ display: 'flex', backgroundColor: 'white', borderColor: 'rgba(0,0,0,0.5)', position: 'absolute', top: '0', left: '20%', zIndex: 1,  justifyContent: 'center', alignItems: 'center', borderWidth: 1, borderStyle: 'solid', padding: 5, borderRadius: 5}}
          >

          {/*This is the left right page and close buttons  width: 612*1.2, height: 792*1.2, */}
          {isShown &&
            <div style={{ borderRadius: 7, top: 50, position: 'absolute', zIndex: 2, justifyContent: 'center', alignItems: 'center', display: 'flex', width: 150, height: 50,
            WebKitBoxShadow: "3px 3px 3px #000000",
            MozBoxShadow: "3px 3px 3px rgba(0,0,0,0.5)",
            boxShadow: "3px 3px 3px rgba(0,0,0,0.5)"}}
            >
              <button
              //style={{ left: 0, top: 0}}
              style={{ fontWeight: '200', backgroundColor: 'rgba(255,255,255,1)', borderStyle: 'none', borderBottomLeftRadius: 7, borderTopLeftRadius: 7, height: 50, width: 75}}
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              >
              {'<'}
              </button>
              <button
                onClick={() => props.togglePDFViewer(false)}
                style={{ fontWeight: '200', backgroundColor: 'rgba(255,255,255,1)', borderStyle: 'none', height: 50, width: 75 }}
              >
                Close
              </button>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                style={{ fontWeight: '200', backgroundColor: 'rgba(255,255,255,1)', borderStyle: 'none', height: 50, width: 75}}
              >
                {'>'}
              </button>

              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={() => setScale(scale-0.25)}
                style={{ fontWeight: '200', backgroundColor: 'rgba(255,255,255,1)', borderStyle: 'none', height: 50, width: 75}}
              >
                {'-'}
              </button>

              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={() => setScale(scale+0.25)}
                style={{ fontWeight: '200', backgroundColor: 'rgba(255,255,255,1)', borderStyle: 'none', borderTopRightRadius: 7, borderBottomRightRadius: 7, height: 50, width: 75}}
              >
                {'+'}
              </button>


            </div>
            }

            <>
              <Document
                file={localStorage.getItem('videoURL') + 'attachments/' + props.movie.attachment_key}
                onLoadSuccess={onDocumentLoadSuccess}
                className="pdf-table"
                renderMode="none"
                loading={<div style={{width: 200, height: 200, backgroundColor: 'rgba(0,0,0,0.15)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><img src={LoadingSpinner} style={{ width: '2rem'}} alt="loading..." /></div>}
              >
                <Page pageNumber={pageNumber} scale={scale}/>
              </Document>
            </>

          </div>
        </div>
      )}


      </div>
    </Auxx>
  );
}
const buttonStyle = {
  backgroundColor: 'white'
}
/*
const Root = styled.div`
  font-family: sans-serif;
  text-align: center;
  position: relative;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
`;

const Dialog = styled.div`
  background: white;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;
*/
