import React from 'react'

import Auxx from '../../hoc/Auxx';
import Backdrop from './Backdrop'


export default function Modal(props) {
   return (
      <Auxx>
         <div
            className={(props.show ? "movie-modal show" : "movie-modal hide")}
            style={{ 
              width: '100%', 
              height: '100%', 
              top: 0, 
              flex: 1,
              display: 'flex', 
              backgroundColor: 'rgba(0,0,0,0.75)',
            }}
            >
            <div onClick={() => props.toggleVideoModal(false)} style={{ backgroundColor:'rgba(0,0,0,0.0)', flex: 1}}/>
            {props.children}
            <div onClick={() => props.toggleVideoModal(false)}style={{ backgroundColor:'rgba(0,0,0,0.0)', flex: 1}}/>
         </div>
      </Auxx>
   )
}
