import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Form, FormGroup, Input } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import * as actions from 'Actions';
import Amplify, { Auth } from 'aws-amplify';
import { whiteLabel } from 'Whitelabel';

const awsConfig = whiteLabel('awsConfig');
const introTitle = whiteLabel('introTitle');
const highlightColour = whiteLabel('highlightColour');

Amplify.configure(awsConfig);

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
       code: '',
       stage: 0,
       password: '',
       confirmPassword: '',
     }
    }

  /**
	 * On User Login
	 */
   async onUserPressButton() {
     this.props.saveAuthLoading(true)

     if (this.state.stage === 0) {
         Auth.forgotPassword(this.props.authEmail)
        .then(data => {
          const r = window.alert('Please check your email for the password reset verifcation code');
          this.props.saveAuthLoading(false)
          this.setState({ stage: 1});
        })
        .catch(err => {
          const r = window.alert(err.message);
          this.props.saveAuthLoading(false)
        });
    }

    if (this.state.stage === 1) {

      if (this.state.password.length <6){
        alert('Password must be at least 6 characters long')
        this.props.saveAuthLoading(false)
        return
      }

      if (this.state.password !== this.state.confirmPassword) {
        alert('Passwords do not match')
        this.props.saveAuthLoading(false)
        return
      }

      this.setState({ screenBlocker: true });
      Auth.forgotPasswordSubmit(this.props.authEmail, this.state.code, this.state.password)
      .then(data => {
        const r = window.alert('Password has been reset, please login');
        this.props.saveAuthLoading(false)
        this.props.changeMainAuthPage('signin')
      })
      .catch(err => {
        const r = window.alert(err.message);
        this.props.saveAuthLoading(false)
      })

    }

   }

  renderSignLoad() {
    return (
    <div style={{ width: 200, backgroundColor: highlightColour, color: 'white', height: 45, borderRadius: 5, fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
     {this.renderSignLoadInner()}
    </div>
   );
  }

  renderSignLoadInner(){
     if(this.props.authLoading===true){
        return(
           <ClipLoader
            sizeUnit={"px"}
            size={25}
            color={'white'}
            loading
           />
        );
      }
      else{
          return('Reset Password')
        }
  }

  render() {

    return (
      <>
      <div className="session-head mb-30">
         <h2 className="font-weight-bold">Forget Password</h2>
      </div>
      <Form>
         <FormGroup className="has-wrapper">
            <Input
               type="mail"
               value={this.props.authEmail}
               disabled={this.state.stage !== 0}
               name="user-mail"
               id="user-mail"
               className="has-input input-lg custom-admin-inputs"
               placeholder="Email"
               onChange={(event) => this.props.saveEmail(event.target.value.trim())}
            />
            <span className="has-icon"><i className="ti-email"></i></span>
         </FormGroup>
         {this.state.stage ===1 &&
           <>
           <FormGroup className="has-wrapper">
              <Input
                 value={this.state.code}
                 disabled={this.state.stage === 0}
                 className="has-input input-lg custom-admin-inputs"
                 placeholder="Code"
                 onKeyDown={this._handleKeyDown}
                 onChange={(event) => this.setState({ code: event.target.value })}
              />
              <span className="has-icon"><i className="ti-lock"></i></span>
           </FormGroup>


           <FormGroup className="has-wrapper">
              <Input
                 value={this.state.password}
                 type="Password"
                 //name="user-pwd"
                 //id="pwd"
                 className="has-input input-lg custom-admin-inputs"
                 placeholder="Password"
                 //onKeyDown={this._handleKeyDown}
                 onChange={(event) => this.setState({ password: event.target.value })}
              />
              <span className="has-icon"><i className="ti-lock"></i></span>
           </FormGroup>

           <FormGroup className="has-wrapper">
              <Input
                 value={this.state.confirmPassword}
                 type="Password"
                 //name="user-pwd"
                 //id="pwd"
                 className="has-input input-lg custom-admin-inputs"
                 placeholder="Confirm Password"
                 //onKeyDown={this._handleKeyDown}
                 onChange={(event) => this.setState({ confirmPassword: event.target.value })}
              />
              <span className="has-icon"><i className="ti-lock"></i></span>
           </FormGroup>
           </>
         }


         <FormGroup className="mb-15">
            <Button
               //color={"primary"}
               //className="btn-block text-white w-50"
               //variant="contained"
               disabled={this.props.authLoading}
               //size="large"
               onClick={() => this.onUserPressButton()}
               style={{ fontWeight: 200}}
            >
               {this.renderSignLoad()}
          </Button>
         </FormGroup>
      </Form>
      </>
    )
}

}

// map state to props
const mapStateToProps = (data) => {
   const { user, loading, owner, authEmail, authLoading } = data.authUser;
   return { user, loading, owner, authEmail, authLoading }
}

export default connect(mapStateToProps, actions)(ResetPassword);
