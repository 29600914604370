import React from 'react';
import { apiCall } from 'CommonFunctions';
import { Button, Input } from 'reactstrap';

let title = '';
let message = '';


export default function Push(props) {

  function checkSend() {

    const r = window.confirm('Do you really want to send this push notifcation to all users?');
    if(r === true)
    {
      document.getElementById('inputA').value = '';
      document.getElementById('inputB').value = '';
      apiCall('pushNotify', {title: title, message: message});
      alert('Push Notifcation Sent');
    }

  }


  return (
      <div style={{ borderStyle: 'solid', width: 650, borderRadius: 5, padding: 5, borderWidth: 0.2, borderColor: 'rgba(0,0,0,1)', height: 150 }}>

        Push Notification
        <Input
            onChange={(e) => title = e.target.value}
            placeholder={'Title'}
            //onChange={(e) => pushButtonFunction(e.target.value)}
            style={{ fontSize: 12, width: 350 }}
            maxLength={40}
            autoComplete='none'
            id='inputA'
        />

        <Input
            onChange={(e) => message = e.target.value }
            //onChange={(e) => pushButtonFunction(e.target.value)}
            style={{ fontSize: 12, width: 600, marginTop: 10 }}
            placeholder={'Message'}
            autoComplete='none'
            maxLength={255}
            id='inputB'
        />

        <Button variant="contained" color="primary" style={{marginBottom: 5, marginTop: 5}} onClick={()=>checkSend()}>Push</Button>
      </div>
  )
}
