import React from 'react'

import Auxx from '../../hoc/Auxx';
import Backdrop from './Backdrop'


export default function Modal(props) {
   const backgroundStyle = {
      backgroundSize: "cover",
      backgroundImage: `url(${props.movie.thumbnailUrl || props.movie.url})`,
   }

   return (
      <Auxx>
         <Backdrop show={props.show} toggleBackdrop={props.modalClosed} />
         <div
            style={backgroundStyle}
            className={(props.show ? "movie-modal show" : "movie-modal hide")}>
            {props.children}
         </div>
      </Auxx>
   )
}
