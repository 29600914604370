import React, { Component } from 'react';
import { connect } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import { Form, FormGroup, Input } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import * as actions from 'Actions';

import countries from './countries';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

//import Dropdown from 'react-bootstrap/Dropdown'

import { whiteLabel } from 'Whitelabel';

const awsConfig = whiteLabel('awsConfig');
const introText = whiteLabel('introText');
const introTitle = whiteLabel('introTitle');
const highlightColour = whiteLabel('highlightColour');
const countrySignUp = whiteLabel('countrySignUp');

Amplify.configure(awsConfig);

class SignUp extends Component {

  state = {
     //email: 'paul@gangemi.com',
     password: '',
     confirmPassword: '',
     //loading: false,
     name: '',
     //couponVisible: false,
     //coupon: '',
     country: ''
  }

  /**
	 * On User Login
	 */
   async onUserSignUp() {
       this.props.saveUserName(this.state.name)//This is then used when we call getuserdata to save the users name when we sign up
       this.props.saveCountry(this.state.country)
       this.props.saveAuthLoading(true)

      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(this.props.authEmail) === false) {
        alert('Invalid email address')
        this.props.saveAuthLoading(false)
        return
      }

     if (this.state.password.length <6){
       alert('Password must be at least 6 characters long')
       this.props.saveAuthLoading(false)
       return
     }

     if (this.state.password !== this.state.confirmPassword) {
       alert('Passwords do not match')
       this.props.saveAuthLoading(false)
       return
     }

     if (this.state.name === ''){
       alert('Please enter a name')
       this.props.saveAuthLoading(false)
       return
     }

     if (this.state.country === ''){
       alert('Please select a country')
       this.props.saveAuthLoading(false)
       return
     }



  Auth.signUp({
    username: this.props.authEmail,
    password: this.state.password,
    attributes: {
      email: this.props.authEmail,
    },
  })
  .then(successs => {
    const r = window.alert('Please check your email for the verifcation code')

    this.props.changeMainAuthPage('confirmEmail')
  }).catch(error => {
    console.log('error', error)
    console.log('error.message', error.message)
    alert(error.message);
  }).finally(()=>{
    this.props.saveAuthLoading(false)
  })


   }

  renderSignLoad() {
    return (
    <div style={{ width: 200, backgroundColor: highlightColour, color: 'white', height: 45, borderRadius: 5, fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
     {this.renderSignLoadInner()}
    </div>
   );
  }

  renderSignLoadInner(){
     if(this.props.authLoading===true){
        return(
           <ClipLoader
            sizeUnit={"px"}
            size={25}
            color={'white'}
            loading
           />
        );
      }
      else{
          return('Sign Up')
        }
  }

/*
  showCouponBox = () => {
    this.setState({ couponVisible: true })
  }
*/
Capitalize(str){
  return str.charAt(0).toUpperCase() + str.slice(1);
}

  render() {
    const { email, password, confirmPassword, name, coupon } = this.state;
    return (
      <>
      <div className="session-head mb-30">
         <h2 className="font-weight-bold">Register your account</h2>
      </div>
      <Form>
         <FormGroup className="has-wrapper">
            <Input
               //type="mail"
               value={this.props.authEmail}
               //name="user-mail"
               //id="user-mail"
               className="has-input input-lg custom-admin-inputs"
               placeholder="Email"
               onChange={(event) => this.props.saveEmail(event.target.value.trim())}
               style={{ fontWeight: 200}}
            />
            <span className="has-icon"><i className="ti-email"></i></span>
         </FormGroup>
         <FormGroup className="has-wrapper">
            <Input
               value={password}
               type="Password"
               //name="user-pwd"
               //id="pwd"
               className="has-input input-lg custom-admin-inputs"
               placeholder="Password"
               //onKeyDown={this._handleKeyDown}
               onChange={(event) => this.setState({ password: event.target.value })}
               style={{ fontWeight: 200}}
            />
            <span className="has-icon"><i className="ti-lock"></i></span>
         </FormGroup>

         <FormGroup className="has-wrapper">
            <Input
               value={confirmPassword}
               type="Password"
               //name="user-pwd"
               //id="pwd"
               className="has-input input-lg custom-admin-inputs"
               placeholder="Confirm Password"
               //onKeyDown={this._handleKeyDown}
               onChange={(event) => this.setState({ confirmPassword: event.target.value })}
               style={{ fontWeight: 200}}
            />
            <span className="has-icon"><i className="ti-lock"></i></span>
         </FormGroup>

         <FormGroup className="has-wrapper">
            <Input
               value={name}
               className="has-input input-lg custom-admin-inputs"
               placeholder="First Name"
               onChange={(event) => this.setState({ name: this.Capitalize(event.target.value) })}
               style={{ fontWeight: 200}}
            />
            <span className="has-icon"><i className="ti-lock"></i></span>
         </FormGroup>

         {countrySignUp &&
           <FormGroup className="has-wrapper">
             <Dropdown
              //className="has-input input-lg custom-admin-inputs"
              controlClassName="has-input input-lg custom-admin-inputs"
              //menuClassName="has-input input-lg custom-admin-inputs"
              placeholderClassName='register-country-dropdown-placeholder'
              options={countries}
              onChange={item => this.setState({country: item.value})}
              //value={'Australia'}
              placeholder="Country"
            />
           </FormGroup>
         }

{/*
         {this.state.couponVisible &&
         <FormGroup className="has-wrapper">
            <Input
               value={coupon}
               //name="user-pwd"
               //id="pwd"
               className="has-input input-lg"
               placeholder="Coupon"
               //onKeyDown={this._handleKeyDown}
               onChange={(event) => this.setState({ coupon: event.target.value })}
            />
            <span className="has-icon"><i className="ti-lock"></i></span>
         </FormGroup>
         }
         {!this.state.couponVisible &&
           <FormGroup className="has-wrapper">
           <div style={{cursor: 'pointer'}} onClick={this.showCouponBox}>Have a coupon?</div>
           </FormGroup>
         }
*/}

         <FormGroup className="mb-15">
            <Button
               //color={"primary"}
               //className="btn-block text-white w-50"
               //variant="contained"
               disabled={this.props.authLoading}
               //size="large"
               onClick={() => this.onUserSignUp()}
               style={{ fontWeight: 200}}
            >
               {this.renderSignLoad()}
          </Button>
         </FormGroup>



      </Form>

            {/* <p className="text-muted">By signing up you agree to the</p> */}
            {/*
            <p className="mb-0"><a href="/forgetpassword" className="text-muted">Forgot password?</a></p>
            */}
      </>
    )
}

}

// map state to props
const mapStateToProps = (data) => {
   const { user, owner, authEmail, authLoading } = data.authUser;
   return { user, owner, authEmail, authLoading }
}

export default connect(mapStateToProps, actions)(SignUp);
