import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { whiteLabel } from 'Whitelabel';
import { apiCall, uploadThumbnailFile, deleteThumbnail } from 'CommonFunctions';
import LoadingSpinner from 'Assets/images/loading.gif';


export default class RestrictedList extends Component {

  state = {
    tmp: [],
    loading: false,
    uploadProgressPerc: 0,
  }

  uploadProgress = (percent) => {
    this.setState({ uploadProgressPerc: percent })
  }

  funcUploadThumbnail = async (evt) => {
    this.setState({ loading: true });

    //Upload the new image
    const v = await uploadThumbnailFile(evt, this.uploadProgress);
    if (v.success) {
      this.props.didWeUploadThumbnail({ old: this.props.selectedWorkout.image_key, new: v.key}) //Save old and new key so we can delete correct one later depending if we save or cancel
      this.props.changeText(v.key, 'image_key'); //Save the new key to the object so it can be saved when we hit save We should reload everything here in stead
    }

    this.setState({ loading: false });

  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', borderStyle: 'solid', borderRadius: 5, padding: 5, borderWidth: 0.2, borderColor: 'rgba(0,0,0,1)', height: 150, width: 250, marginTop: 40 }}>
            <img src={LoadingSpinner} style={{ width: '3rem', marginBottom: 20 }} alt="loading..." />
            <span style={{ color: 'rgba(0,0,0,0.6)'}}>{this.state.uploadProgressPerc}%</span>
        </div>
      );
    }
    if (this.props.selectedWorkout.video_key !== '-') {
      return (
        <div style={{ borderStyle: 'solid', borderRadius: 5, padding: 5, borderWidth: 0.2, borderColor: 'rgba(0,0,0,1)', height: 220, width: 280, marginTop: 20 }}>
          <div style={{ marginBottom: 5 }}>Thumbnail Image</div>
            <div>
            <input
                type="file"
                accept='image/*'
                onChange={(evt) => this.funcUploadThumbnail(evt)}
                style={{ width: 100 }}
            />
            {(this.props.selectedWorkout.image_key !== '-' && this.props.selectedWorkout.image_key !== undefined) &&
              <img
                style={{ width: 1280/5, height: 720/5, marginTop: 10, borderRadius: 20, marginLeft: 5}}
                src={`${localStorage.getItem('videoURL')}thumbnails/${this.props.selectedWorkout.image_key}`}//We use localStorage because redux isnt keeping state when reloading, so we save videoURL to localStorage so we can always grab it
                alt={'thumbnail for video'}
              />
            }
            </div>
        </div>
     );
   } else {
      return null
   }
  }

}
