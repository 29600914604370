import { configureStore } from './myStore';
import {
  awsConfig_isofit,
  awsConfig_netfit,
  awsConfig_isofit_dev,
  awsConfig_recx,
  awsConfig_britt,
  awsConfig_strong,
  awsConfig_netfitnz
 } from './aws-exports';

//import { thumbnailImages } from './thumbnail_images';

const storeState = configureStore().getState();

const { owner } = storeState.authUser;


const data = {
  headerBackground: {
    dev:      require('Assets/images/dev/header-bg.jpg'),
    isofit:   require('Assets/images/isofit/mainbackground4.jpg'),
    netfit:   require('Assets/images/netfit/netfit_back_12.jpg'),
    recx:     require('Assets/images/recx/bg2.jpg'),
    britt: require('Assets/images/britt/a.jpg'),
    strong: require('Assets/images/strong/bg.jpg'),
    netfitnz:   require('Assets/images/netfitnz/bg1.jpg'),
  },
  highlightColour: {
    dev: '#168235',
    isofit: '#00BBF2',
    netfit: '#d3449c',
    recx: '#e50000',
    britt: '#39393a',
    strong: '#2e2e2e',
    netfitnz: '#39393a',
  },
  logo: {
    dev: null,//require('Assets/images/khan/logo.png'),
    isofit:   require('Assets/images/isofit/ISOFIT_RGB.png'),
    netfit:   require('Assets/images/netfit/netfit_logo.png'),
    recx:     require('Assets/images/recx/logo2.png'),
    britt: require('Assets/images/britt/logo.png'),
    strong:   require('Assets/images/strong/logo.png'),
    netfitnz:   require('Assets/images/netfitnz/netfitnz_logo.png'),
  },
  awsConfig: {
    dev: awsConfig_isofit_dev,
    isofit: awsConfig_isofit,
    netfit: awsConfig_netfit,
    recx: awsConfig_recx,
    britt: awsConfig_britt,
    strong: awsConfig_strong,
    netfitnz: awsConfig_netfitnz,
  },
  introTitle: {
    dev: 'Dev introTitle',
    isofit: 'ISOFIT On Demand',
    netfit: 'NETFIT On Demand',
    recx: 'RecXpress On Demand',
    britt: 'Body Active by Britt',
    strong: 'STRONG Rewired',
    netfitnz: 'NETFIT NZ On Demand',
  },
  introText: {
    dev: 'Dev introText',
    isofit: 'Sign in with your details from the ISOFIT app',
    netfit: 'Sign in with your details from the NETFIT app',
    recx: 'Sign in with your details from the RecXpress app',
    britt: 'Sign in here with your details from the mobile app',
    strong: 'Sign in here with your details from the STRONG Rewired app',
    netfitnz: 'Sign in with your details from the NETFIT NZ app',
  },
  headerText: {
    dev: `Dev account....`,
    isofit: 'Watch all your ISOFIT videos here, on-demand.',
    netfit: 'Watch all your NETFIT videos here, on-demand.',
    recx: 'At Home exercises and gym workouts you can access anywhere, anytime, on-demand.',
    britt: 'Get Active, Body Active by Britt.',
    strong: 'Watch all your STRONG Rewired videos here, on-demand.',
    netfitnz: 'Watch all your NETFIT NZ videos here, on-demand.',
  },
  //Important! setting this to true will lock normal users out and they must manually be added
/*
  restrictedList: { //If true, Checks the restricted list database and only allows sign in if the email is in the database
    dev: false,
    isofit: false,
    netfit: false,
    recx: false,
    britt: false,
    strong: true,
    netfitnz: false,
  },
  */
  multiTagLevels: { //This is the NETFIT mutli tag levels
    dev: true,
    isofit: false,
    netfit: true,
    recx: false,
    britt: false,
    strong: false,
    netfitnz: true,
  },
  pdf: {
    dev: true,
    isofit: true,
    netfit: true,
    recx: false,
    britt: false,
    strong: false,
    netfitnz: true,
  },
  trackingAndTimer: { //Brit tracking and timer add on
    dev: false,
    isofit: true,
    netfit: false,
    recx: false,
    britt: true,
    strong: false,
    netfitnz: false,
  },
  adminPages: {//These are the pages and hence the functions available to the admin
    dev:      {videos: true, streaming: true, push: true, addPurchases: true, whitelist: false, uploadVideosVOD: true, tagOrdering: true, coupon: false},
    isofit:   {videos: true, streaming: true, push: true, addPurchases: true, whitelist: false, uploadVideosVOD: true, tagOrdering: true, coupon: false},
    netfit:   {videos: true, streaming: true, push: true, addPurchases: true, whitelist: false, uploadVideosVOD: true, tagOrdering: true, coupon: true},
    recx:     {videos: true, streaming: false, push: false, addPurchases: true, whitelist: false, uploadVideosVOD: false, tagOrdering: true, coupon: false},
    britt:    {videos: true, streaming: true, push: true, addPurchases: true, whitelist: false, uploadVideosVOD: false, tagOrdering: true, coupon: false},
    strong:   {videos: true, streaming: false, push: false, addPurchases: false, whitelist: true, uploadVideosVOD: false, tagOrdering: true, coupon: false},
    netfitnz: {videos: true, streaming: true, push: true, addPurchases: true, whitelist: false, uploadVideosVOD: true, tagOrdering: true, coupon: true},
  },
  countrySignUp: {
    dev:    false,
    isofit: false,
    netfit: true,
    recx:   false,
    britt:  false,
    strong: false,
    netfitnz: true,
  },
}

export const whiteLabel = (param) => {
  if (data[param][owner] === undefined) {
    console.warn('---- Cannot find whitelabel parameter ', param);
    console.error('---- Cannot find whitelabel parameter ', param);
  }
  return data[param][owner];
};
