import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { whiteLabel } from 'Whitelabel';
import { apiCall, uploadAttachment, deleteAttachment } from 'CommonFunctions';
import LoadingSpinner from 'Assets/images/loading.gif';


export default class Tracking extends Component {

  state = {
    maxWeekCount: 3,
    weeks: 1,
    rows: 1,
    w1: '',
    w2: '',
    w3: '',
    w4: '',
    w5: '',
    w6: '',
    w7: '',
    w8: '',
    w9: '',
    w10: '',
    w11: '',
    w12: '',
    w13: '',
    w14: '',
    buttonDisabled: true,
    tmp: false,
    w1rep: ['','','','','','','','','','','','','',''],
    w2rep: ['','','','','','','','','','','','','',''],
    w3rep: ['','','','','','','','','','','','','',''],
    w4rep: ['','','','','','','','','','','','','',''],
    w5rep: ['','','','','','','','','','','','','',''],
    w6rep: ['','','','','','','','','','','','','',''],
    w7rep: ['','','','','','','','','','','','','',''],
    w8rep: ['','','','','','','','','','','','','',''],
    w9rep: ['','','','','','','','','','','','','',''],
    w10rep: ['','','','','','','','','','','','','',''],
    w11rep: ['','','','','','','','','','','','','',''],
    w12rep: ['','','','','','','','','','','','','',''],
    w13rep: ['','','','','','','','','','','','','',''],
    w14rep: ['','','','','','','','','','','','','',''],
  }

  componentDidUpdate = async (prevProps) => {
    //We cant update everytime or else it makes inifite loop, need to check to see if props have changed, and if so update
/*
    console.log('prevProps', prevProps.trackingData)
    console.log('trackingData', this.props.trackingData)

    console.log('prevProps Reps:', prevProps.trackingData)
    console.log('trackingDataReps', this.props.trackingDataReps)
    */

    //if (prevProps.exerciseUID !== this.props.exerciseUID) {
    if (this.props.trackingFirstLoad || prevProps.exerciseUID !== this.props.exerciseUID) {
      this.props.setFirstLoadFalse();

      if (this.props.trackingData.length > 0 ){
        //setup the exercise names
        this.setState({
            weeks: this.props.trackingData[0].weeks,
            rows: this.props.trackingData[0].rowCount,
            w1: this.props.trackingData[0].w1,
            w2: this.props.trackingData[0].w2,
            w3: this.props.trackingData[0].w3,
            w4: this.props.trackingData[0].w4,
            w5: this.props.trackingData[0].w5,
            w6: this.props.trackingData[0].w6,
            w7: this.props.trackingData[0].w7,
            w8: this.props.trackingData[0].w8,
            w9: this.props.trackingData[0].w9,
            w10: this.props.trackingData[0].w10,
            w11: this.props.trackingData[0].w11,
            w12: this.props.trackingData[0].w12,
            w13: this.props.trackingData[0].w13,
            w14: this.props.trackingData[0].w14,
          });
          //Setup the rep numbers
          this.setState({
            w1rep: this.setupTrackingRepData(1),
            w2rep: this.setupTrackingRepData(2),
            w3rep: this.setupTrackingRepData(3),
            w4rep: this.setupTrackingRepData(4),
            w5rep: this.setupTrackingRepData(5),
            w6rep: this.setupTrackingRepData(6),
            w7rep: this.setupTrackingRepData(7),
            w8rep: this.setupTrackingRepData(8),
            w9rep: this.setupTrackingRepData(9),
            w10rep: this.setupTrackingRepData(10),
            w11rep: this.setupTrackingRepData(11),
            w12rep: this.setupTrackingRepData(12),
            w13rep: this.setupTrackingRepData(13),
            w14rep: this.setupTrackingRepData(14),
          })
      } else {
        console.log('no tracking data so can give default values')
      }
    }
  }

  setupTrackingRepData = (weekNo) => {
    if (this.props.trackingDataReps[0][`w${weekNo}`] === ''){
      return ["","","","","","","","","","","","","",""]
    } else {
      return JSON.parse(this.props.trackingDataReps[0][`w${weekNo}`]);
    }
  }

  updateWeekCount = (value) => {
    if (value >= 1 & value <= this.state.maxWeekCount) { //This will only allow upto 4 weeks to be selected
      this.setState({ weeks: value, buttonDisabled: false }, ()=>this.props.updateTrackingData(this.state));
    }
  }

  updateExerciseRowCount = (value) => {
    if (value >= 1 & value <= 14) {
      this.setState({ rows: value });
    }

    //Clear the states for over the current week
    for (let i = parseInt(value)+1; i <= 14; i++) {
      this.setState({ ['w'+i]: '', buttonDisabled: false });
    }

    //Have a tmp switch flag, so we can put in a callback once states are updated, so we know the above state changes are finished then we update the tracking data
    //Hackerish way of doing it but good enough for now
    this.setState({ tmp: !this.state.tmp}, ()=>this.props.updateTrackingData(this.state));
  }


updateExerciseName = (name, week) => {
  this.setState({ ['w'+week]: name, buttonDisabled: false }, ()=>this.props.updateTrackingData(this.state));
}

updateRepCount = (repCount, week, row) => {
  //console.log('repCount value:', repCount)
  //console.log('repCount ParseInt:', parseInt(repCount))
  if (isNaN(parseInt(repCount)) && repCount !== '') {
    //console.log('in NAN')
    return
  }
  //console.log('repCountValue:', repCount, ' week:', week, ' row:', row);
  const tmpRepDataWeek = JSON.parse(JSON.stringify(this.state[`w${week+1}rep`]));


  tmpRepDataWeek[row]=repCount
  //console.log('tmpRepDataWeek', tmpRepDataWeek)
  this.setState({ [`w${week+1}rep`]: tmpRepDataWeek }, ()=>this.props.updateTrackingData(this.state));



}

createGrid = () => {
  let rowsD = []
  for (let j = 0; j < this.state.rows; j++) {

    let weeksD = []
    for (let k = 0; k < this.state.weeks; k++) {
      weeksD.push(
        <div key={k}>
          <Input
            style={{ width: 30, height: 20, fontSize: 10, padding: 0, textAlign: 'center'}}
            onChange={(e)=> this.updateRepCount(e.target.value, k, j)}
            value={this.state[`w${k+1}rep`][j]}
           />
        </div>
      )
    }

    rowsD.push(
      <div key={j} style={{ display: 'flex', width: 200 }}>
      <Input
        maxLength="13"
        style={{ width: 120, height: 20, fontSize: 10}}
        onChange={(e)=> this.updateExerciseName(e.target.value, j+1)}
        value={this.state[`w${j+1}`]}
        />
      {weeksD}
      </div>
    )
  }

  return (
    <div style={{marginLeft: -50}}>
      Exercise Name
      {rowsD}
    </div>
  )
}


  render() {
    return (
      <>
      <div style={{ display: 'flex', width: 200}}>
        <div style={{ width: 100 }}>
          <div style={{ flex: 1}}>
          Exercises
          </div>
          <div style={{ flex: 1}}>
          <Input
            onChange={(e)=> this.updateExerciseRowCount(e.target.value)}
            style={{ width: 70, height: 40 }}
            value={this.state.rows}
            type="number"
          />
          </div>
        </div>

        <div style={{ width: 100 }}>
          Weeks
          <Input
            onChange={(e)=> this.updateWeekCount(e.target.value)}
            style={{ width: 70, height: 40 }}
            value={this.state.weeks}
            type="number"
          />
        </div>
      </div>

      {this.createGrid()}

      </>

    )
  }

}
