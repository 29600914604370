/**
 * Signin Firebase
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import QueueAnim from 'rc-queue-anim';
import { ClipLoader } from 'react-spinners';
import * as actions from '../../actions';

import SignUp from './components/signup'
import SignIn from './components/signin'
import ConfirmEmail from './components/confirmemail'
import ResetPassword from './components/resetpassword'

import { whiteLabel } from 'Whitelabel';
const logo = whiteLabel('logo');
const headerBackground = whiteLabel('headerBackground');


class Signin extends Component {

   renderSwitch(param) {
     switch(this.props.authScreen) {
       case 'signin':
         return <SignIn history={this.props.history}/>;
       case 'signup':
         return <SignUp/>;
       case 'confirmEmail':
         return <ConfirmEmail/>;
       case 'resetPassword':
         return <ResetPassword/>;
       default:
         return <div>Error</div>;
     }
   }


	/**
	 * On User Sign Up
	 */
   onUserSignUp() {
     if (this.props.authScreen === 'signin') {
       this.props.changeMainAuthPage('signup')
     } else {
       this.props.changeMainAuthPage('signin')
     }
   }


   render() {
      return (
         <QueueAnim type="bottom" duration={2000}>
            <div className="rct-session-wrapper-login" style={{fontSize: '100%', backgroundImage: "url("+headerBackground+")"}}>
               {this.props.authLoading &&
                  <LinearProgress />
               }
               <AppBar position="static" className="login-header">
                  <Toolbar>
                     <div className="auth-container">
                        <div className="d-flex justify-content-between">
                           <div className="session-logo">
                              <Link to="/">
                                 <img src={logo} alt="session-logo" className="img-fluid" width="220" height="70" />
                              </Link>
                           </div>

                           <div>
                              <a className="mr-15 blacktext" style={{paddingTop: 30, fontWeight: 200}} onClick={() => this.onUserSignUp()}>{this.props.authScreen === 'signin' ? 'Create New account?' : 'Go back to sign in'}</a>
                              <Button variant="contained" style={{ fontWeight: 200}} className="btn-light" onClick={() => this.onUserSignUp()}>{this.props.authScreen === 'signin' ? 'Sign Up' : 'Sign In'}</Button>
                           </div>

                        </div>
                     </div>
                  </Toolbar>
               </AppBar>

               <div className="session-inner-wrapper">
                  <div className="auth-container">
                     <div className="row row-eq-height">
                        <div className="col"></div>
                        <div className="col-sm-7 col-md-7 col-lg-8">
                           <div className="session-body text-center">
                            {this.renderSwitch()}
                           </div>
                        </div>
                        <div className="col"></div>
                     </div>
                  </div>
               </div>
            </div>
         </QueueAnim>
      );
   }
}

// map state to props
const mapStateToProps = (data) => {
   const { user, owner, authScreen, authLoading } = data.authUser;
   return { user, owner, authScreen, authLoading }
}

export default connect(mapStateToProps, actions)(Signin);
