const countries = [
`Afghanistan`,
`Albania`,
`Algeria`,
`American Samoa`,
`Andorra`,
`Angola`,
`Anguilla`,
`Antarctica`,
`Antigua And Barbuda`,
`Argentina`,
`Armenia`,
`Aruba`,
`Australia`,
`Austria`,
`Azerbaijan`,
`Bahamas`,
`Bahrain`,
`Bangladesh`,
`Barbados`,
`Belarus`,
`Belgium`,
`Belize`,
`Benin`,
`Bermuda`,
`Bhutan`,
`Bolivia`,
`Bosnia And Herzegovina`,
`Botswana`,
`Bouvet Island`,
`Brazil`,
`British Indian Ocean Territory`,
`Brunei Darussalam`,
`Bulgaria`,
`Burkina Faso`,
`Burundi`,
`Cambodia`,
`Cameroon`,
`Canada`,
`Cape Verde`,
`Cayman Islands`,
`Central African Republic`,
`Chad`,
`Chile`,
`China`,
`Christmas Island`,
`Cocos (keeling) Islands`,
`Colombia`,
`Comoros`,
`Congo`,
`Cook Islands`,
`Costa Rica`,
`Cote D'ivoire`,
`Croatia`,
`Cuba`,
`Cyprus`,
`Czech Republic`,
`Denmark`,
`Djibouti`,
`Dominica`,
`Dominican Republic`,
`East Timor`,
`Ecuador`,
`Egypt`,
`El Salvador`,
`Equatorial Guinea`,
`Eritrea`,
`Estonia`,
`Ethiopia`,
`Falkland Islands (malvinas)`,
`Faroe Islands`,
`Fiji`,
`Finland`,
`France`,
`French Guiana`,
`French Polynesia`,
`French Southern Territories`,
`Gabon`,
`Gambia`,
`Georgia`,
`Germany`,
`Ghana`,
`Gibraltar`,
`Greece`,
`Greenland`,
`Grenada`,
`Guadeloupe`,
`Guam`,
`Guatemala`,
`Guinea`,
`Guinea-bissau`,
`Guyana`,
`Haiti`,
`Honduras`,
`Hong Kong`,
`Hungary`,
`Iceland`,
`India`,
`Indonesia`,
`Iran, Islamic Republic Of`,
`Iraq`,
`Ireland`,
`Israel`,
`Italy`,
`Jamaica`,
`Japan`,
`Jordan`,
`Kazakstan`,
`Kenya`,
`Kiribati`,
`Korea, Republic Of`,
`Kosovo`,
`Kuwait`,
`Kyrgyzstan`,
`Lao`,
`Latvia`,
`Lebanon`,
`Lesotho`,
`Liberia`,
`Libyan Arab Jamahiriya`,
`Liechtenstein`,
`Lithuania`,
`Luxembourg`,
`Macau`,
`Macedonia`,
`Madagascar`,
`Malawi`,
`Malaysia`,
`Maldives`,
`Mali`,
`Malta`,
`Marshall Islands`,
`Martinique`,
`Mauritania`,
`Mauritius`,
`Mayotte`,
`Mexico`,
`Moldova, Republic Of`,
`Monaco`,
`Mongolia`,
`Montserrat`,
`Montenegro`,
`Morocco`,
`Mozambique`,
`Myanmar`,
`Namibia`,
`Nauru`,
`Nepal`,
`Netherlands`,
`Netherlands Antilles`,
`New Caledonia`,
`New Zealand`,
`Nicaragua`,
`Niger`,
`Nigeria`,
`Niue`,
`Norfolk Island`,
`Northern Mariana Islands`,
`Norway`,
`Oman`,
`Pakistan`,
`Palau`,
`Palestine`,
`Panama`,
`Papua New Guinea`,
`Paraguay`,
`Peru`,
`Philippines`,
`Pitcairn`,
`Poland`,
`Portugal`,
`Puerto Rico`,
`Qatar`,
`Reunion`,
`Romania`,
`Russian Federation`,
`Rwanda`,
`Saint Helena`,
`Saint Kitts And Nevis`,
`Saint Lucia`,
`Saint Pierre And Miquelon`,
`Saint Vincent And The Grenadines`,
`Samoa`,
`San Marino`,
`Sao Tome And Principe`,
`Saudi Arabia`,
`Senegal`,
`Serbia`,
`Seychelles`,
`Sierra Leone`,
`Singapore`,
`Slovakia`,
`Slovenia`,
`Solomon Islands`,
`Somalia`,
`South Africa`,
`Spain`,
`Sri Lanka`,
`Sudan`,
`Suriname`,
`Svalbard And Jan Mayen`,
`Swaziland`,
`Sweden`,
`Switzerland`,
`Syrian Arab Republic`,
`Taiwan, Province Of China`,
`Tajikistan`,
`Tanzania, United Republic Of`,
`Thailand`,
`Togo`,
`Tokelau`,
`Tonga`,
`Trinidad And Tobago`,
`Tunisia`,
`Turkey`,
`Turkmenistan`,
`Turks And Caicos Islands`,
`Tuvalu`,
`Uganda`,
`Ukraine`,
`United Arab Emirates`,
`United Kingdom`,
`United States`,
`Uruguay`,
`Uzbekistan`,
`Vanuatu`,
`Venezuela`,
`Viet Nam`,
`Virgin Islands, British`,
`Virgin Islands, U.s.`,
`Wallis And Futuna`,
`Western Sahara`,
`Yemen`,
`Zambia`,
`Zimbabwe`];

export default countries;
