import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { whiteLabel } from 'Whitelabel';
import { apiCall, uploadAttachment, deleteAttachment } from 'CommonFunctions';
import LoadingSpinner from 'Assets/images/loading.gif';


export default class RestrictedList extends Component {

  state = {
    tmp: [],
    loading: false,
    uploadProgressPerc: 0,
  }

  uploadProgress = (percent) => {
    this.setState({ uploadProgressPerc: percent })
  }

  funcUploadAttachment = async (evt) => {
    this.setState({ loading: true });

    //Upload the new image
    const v = await uploadAttachment(evt, this.uploadProgress);
    if (v !== false){
      //await apiCall('addAttachment', { uid: this.props.selectedWorkout.uid, attachment_key: v.key}) //Save the key to RDS
      //await deleteAttachment(this.props.selectedWorkout.attachment_key) //Delete old one
      this.props.didWeUploadAttachment({ old: this.props.selectedWorkout.attachment_key, new: v.key})
      this.props.changeText(v.key, 'attachment_key');
    }

    this.setState({ loading: false });

  }

  removeAttachment = () => {
    this.props.didWeUploadAttachment({ old: this.props.selectedWorkout.attachment_key, new: '-'})
    this.props.changeText('-', 'attachment_key');
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', borderStyle: 'solid', borderRadius: 5, padding: 5, borderWidth: 0.2, borderColor: 'rgba(0,0,0,1)', height: 150, width: 250, marginTop: 40 }}>
            <img src={LoadingSpinner} style={{ width: '3rem', marginBottom: 20 }} alt="loading..." />
            <span style={{ color: 'rgba(0,0,0,0.6)'}}>{this.state.uploadProgressPerc}%</span>
        </div>
      );
    }

      return (
        <div style={{ borderStyle: 'solid', borderRadius: 5, padding: 5, borderWidth: 0.2, borderColor: 'rgba(0,0,0,1)', height: 90, width: 250, marginTop: 20 }}>
          <div style={{ marginBottom: 5 }}>Attach PDF</div>
          <div style={{ marginBottom: 5, fontSize: 10 }}>{this.props.selectedWorkout.attachment_key === '-' ? 'No Attachment' : this.props.selectedWorkout.attachment_key}</div>
          <input
              type="file"
              accept='application/pdf'
              onChange={(evt) => this.funcUploadAttachment(evt)}
              style={{ width: 100 }}
          />
          <Button style={{height: 30, width: 80, fontSize: 12}} variant="contained" onClick={()=>this.removeAttachment()}>Remove</Button>
        </div>
    );
  }

}
